import { Grid } from '@mui/material'
import React, { useState } from 'react'

import { TitleContainer } from '@/components/page-layouts/title-container'
import NoteCard from '@/features/notes/note-card'

interface Note {
  title: string
  content: string
  date: string
  topBorderColor: string
}

const NoteCardContainer: React.FC = () => {
  const [notes, setNotes] = useState<Note[]>([
    {
      title: 'First Note',
      content: 'This is the first note',
      date: '2024-07-09',
      topBorderColor: '#f4b324',
    },
    {
      title: 'Second Note',
      content: 'This is the second note',
      date: '2024-07-10',
      topBorderColor: '#8524f4',
    },
  ])

  const handleDelete = (index: number) => {
    const newNotes = [...notes]
    newNotes.splice(index, 1)
    setNotes(newNotes)
  }

  const handleEdit = (index: number) => {
    // Handle edit logic here
  }

  return (
    <TitleContainer title="Notes">
      <Grid container spacing={2}>
        {notes.map((note, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <NoteCard
              title={note.title}
              content={note.content}
              date={note.date}
              topBorderColor={note.topBorderColor}
              onDelete={() => handleDelete(index)}
              onEdit={() => handleEdit(index)}
            />
          </Grid>
        ))}
      </Grid>
    </TitleContainer>
  )
}

export default NoteCardContainer
