import React from 'react'

const ProgramCollaboratorsList: React.FC<{ supervisors: any }> = ({
  supervisors,
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      Supervisors:
      {supervisors.map((supervisor: any) => (
        <div
          key={supervisor.id}
          className="flex items-center rounded-full bg-gray-200 px-3 py-1 text-sm text-gray-800"
        >
          {supervisor.name}
        </div>
      ))}
    </div>
  )
}

export default ProgramCollaboratorsList
