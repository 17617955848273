import CssBaseline from '@mui/material/CssBaseline' // Optional: Resets default browser styles
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import React, { ReactNode } from 'react'

import theme from '@/assets/styles/material-ui-theme' // Import your custom theme

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline /> {/* Apply baseline styles */}
      {children}
    </MuiThemeProvider>
  )
}
