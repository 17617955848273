import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'

import { useAppData } from './data-provider'

export function useSocket() {
  return useContext(SocketContext)
}

export const SocketContext = React.createContext<Socket | undefined>(
  {} as Socket,
)

export function SocketProvider({ children }: { children: ReactNode }) {
  const [socket, setSocket] = useState<Socket>()
  const { researcher } = useAppData()

  useEffect(() => {
    const newSocket = io(
      'https://socket-backend-i311.onrender.com', //socket-backend url
      {
        query: { researcherId: researcher?.id },
        transports: ['websocket'],
      },
    )
    setSocket(newSocket)

    return () => {
      newSocket.close()
    }
  }, [researcher])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}
