import React, { useState } from 'react'
import { ulid } from 'ulid'

import { DotsLoader } from '@/components/ui/loading/loading'
import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'

import AddProjectModal from './add-project-modal'
import ProjectList from './project-list'

const HomeProjectList = () => {
  const { researcher } = useAppData()
  const [projects, setProjects] = useState(researcher.projects)
  const [loadingProjects, setLoadingProjects] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { apiPost } = useApiContext()

  const handleAddProject = async (newProject: any) => {
    // Add your logic here for adding a project

    setIsModalOpen(false)
    setLoadingProjects(true)
    try {
      const addedProject = await apiPost('projects/', {
        id: ulid(),
        researcher_ids: [researcher.id],
        ...newProject,
      })
      setProjects([...projects, addedProject])
    } catch (e) {
      alert('There was an error in adding the project')
      console.log('e:', e)
    }
    setLoadingProjects(false)
  }

  const handleAddProjectClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const options = [
    { label: 'Add Project', onClick: handleAddProjectClick },
    // Add more options as needed
  ]

  return (
    <>
      {loadingProjects ? (
        <DotsLoader />
      ) : (
        <ProjectList projects={projects} options={options} />
      )}
      <AddProjectModal
        projects={projects}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onAddProject={handleAddProject}
      />
    </>
  )
}

export default HomeProjectList
