import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { HomePage } from '@/app/routes/homepage'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import Paperbase from '@/paperbase/paperbase'

import { AuthenticationGuard } from './authentication-guard'
import { Callback } from './routes/callback'
import { EmailVerification } from './routes/email-verification'
import { FilePage } from './routes/file'
import { Home } from './routes/home'
import { NewProgram } from './routes/new-program'
import { NewProgramPlan } from './routes/new-program-plan'
import { NotFound } from './routes/not-found'
import { Note } from './routes/note'
import { Profile } from './routes/profile'
import { ProgramPage } from './routes/program'
import { ProgramPlan } from './routes/program-plan'
import { ProgramPlans } from './routes/program-plans'
import { ProjectPage } from './routes/project'

const App: React.FC = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return (
      <div className="">
        <PageAnchorLoader />
      </div>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticationGuard component={Home} />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={Profile} />}
      />
      <Route
        path="/project/:projectId"
        element={<AuthenticationGuard component={ProjectPage} />}
      />
      <Route
        path="/program-plans"
        element={<AuthenticationGuard component={ProgramPlans} />}
      />
      <Route
        path="/program-plans/new"
        element={<AuthenticationGuard component={NewProgramPlan} />}
      />
      <Route
        path="/program-plans/:programPlanId"
        element={<AuthenticationGuard component={ProgramPlan} />}
      />
      <Route
        path="/program-plans/:programPlanId/program/new"
        element={<AuthenticationGuard component={NewProgram} />}
      />
      <Route
        path="/program/:programId"
        element={<AuthenticationGuard component={ProgramPage} />}
      />
      <Route
        path="/note/:noteId"
        element={<AuthenticationGuard component={Note} />}
      />
      <Route
        path="/file/:fileId"
        element={<AuthenticationGuard component={FilePage} />}
      />
      <Route
        path="/paperbase"
        element={<AuthenticationGuard component={Paperbase} />}
      />
      <Route
        path="/homepage"
        element={<AuthenticationGuard component={HomePage} />}
      />
      <Route path="/activate/:uid/:token" element={<EmailVerification />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
