import { ReactComponent as NoteIcon } from '@zendeskgarden/svg-icons/src/16/file-document-stroke.svg'
import { ReactComponent as AddNoteIcon } from '@zendeskgarden/svg-icons/src/16/notes-stroke.svg'
import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { ulid } from 'ulid'

import { DotsLoader } from '@/components/ui/loading/loading'
import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { Note, Project } from '@/types/types'

const NotesList = ({
  externalRequestUrl,
  projectId,
}: {
  externalRequestUrl?: string
  projectId?: Project['id']
}) => {
  const navigate = useNavigate()
  const { researcher } = useAppData()
  const { apiPost, apiGet, apiDelete } = useApiContext()
  const [notes, setNotes] = useState<Note[] | null>(null)

  useEffect(() => {
    const requestUrl = externalRequestUrl || `notes/?user-id=${researcher.id}`
    apiGet(requestUrl).then((response) => {
      setNotes(response)
    })
  }, [researcher.id, apiGet, externalRequestUrl])

  const createNote = () => {
    const id = ulid()
    try {
      apiPost('notes/', {
        id,
        creatorId: researcher.id,
        title: 'Untitled',
        projectId,
      })
      navigate(`/note/${id}`, { state: { newNote: true } })
    } catch (e) {
      console.log('There was an error in adding the note, try again later.')
      console.log('e:', e)
    }
  }

  const handleDelete = (id: string) => {
    if (!notes?.length) return
    apiDelete(`notes/${id}/`)
    setNotes(notes.filter((note) => note.id !== id))
  }

  return (
    <>
      <div className="flex items-center justify-between p-8">
        <div className="text-2xl font-semibold">Notes</div>
        <button
          className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          onClick={createNote}
        >
          <AddNoteIcon />
        </button>
      </div>
      <div>
        {!notes && <DotsLoader />}
        {notes &&
          notes.map((note) => {
            const { id, title } = note
            return (
              <div
                key={id}
                className="flex cursor-pointer items-center rounded-md border-b border-gray-300 p-4 hover:bg-gray-100 focus:outline-none"
              >
                <div className="w-full">
                  <Link to={`/note/${id}`} state={{ note }}>
                    <div className="flex items-center">
                      <div className="mr-4">
                        <NoteIcon />
                      </div>
                      <div> {title} </div>
                    </div>
                  </Link>
                </div>
                <button
                  className="rounded bg-white px-4 py-2 font-bold hover:bg-red-700 hover:text-white"
                  onClick={() => handleDelete(id)}
                >
                  <BsTrash />
                </button>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default NotesList
