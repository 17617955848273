import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'

import theme from '@/assets/styles/material-ui-theme'

import BaseNavigator from './base-navigator'
import Content from './content'
import Header from './header'
import SpeedDialMenu from './speed-dial-menu'

function Copyright() {
  return (
    <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Research Dock
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

const drawerWidth = 256

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          bgcolor: '#2D3034',
        }}
      >
        {isSmUp ? null : (
          <BaseNavigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}
        <BaseNavigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: 'block', xs: 'none' } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}
        >
          <Content />
        </Box>
        <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
          <Copyright />
        </Box>
      </Box>
      <SpeedDialMenu></SpeedDialMenu>
    </Box>
  )
}
