import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D3034',
      dark: '#000000',
      light: '#4F5761',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F4B224',
      dark: '#F3A020',
      light: '#F5BF2A',
      contrastText: '#FFFFFF',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
    },
    common: {},
    error: {
      contrastText: '#FFFFFF',
      main: '#D32F2F',
    },
    warning: {
      contrastText: '#FFFFFF',
      main: '#EF6C00',
    },
    info: {
      contrastText: '#FFFFFF',
      main: '#0288D1',
    },
    success: {
      contrastText: '#FFFFFF',
      main: '#2E7D32',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    h2: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    body1: {
      fontFamily: 'Merriweather, Helvetica, Arial, sans-serif',
    },
    body2: {
      fontFamily: 'Merriweather, Helvetica, Arial, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    overline: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    caption: {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-sizeLarge.MuiButton-containedPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-contrast)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-primary-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-contrast)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-2)',
              opacity: '1',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-6)',
              opacity: '1',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#D6D6D7',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-2)',
              opacity: '1',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-6)',
              opacity: '1',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-common-white_states-main)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#D6D6D7',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-secondary-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-contrast)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-contrast)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-error-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-contrast)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-contrast)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-warning-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-contrast)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-contrast)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-info-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-contrast)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-success-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-contrast)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-contrast)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-primary-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-contrast)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-2)',
              opacity: '1',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-6)',
              opacity: '1',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#D6D6D7',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-2)',
              opacity: '1',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-6)',
              opacity: '1',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-common-white_states-main)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#D6D6D7',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-secondary-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-contrast)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-contrast)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-error-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-contrast)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-contrast)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-warning-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-contrast)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-contrast)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-info-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-contrast)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-success-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-contrast)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-contrast)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-primary-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-contrast)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-2)',
              opacity: '1',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-6)',
              opacity: '1',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#D6D6D7',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-2)',
              opacity: '1',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#9AA1AA',
              boxShadow: 'var(--mui-shadows-6)',
              opacity: '1',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-common-white_states-main)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: '#D6D6D7',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-secondary-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-contrast)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-contrast)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-error-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-contrast)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-contrast)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-warning-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-contrast)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-contrast)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-info-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-contrast)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                opacity: '0.3',
                background: 'var(--mui-palette-success-contrast)',
                padding: '0',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-contrast)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background:
                  'var(--mui-palette-common-white_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-secondary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-error-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-warning-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              '& .MuiTouchRipple-root': {
                width: '71.418px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-success-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background:
                  'var(--mui-palette-common-white_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-secondary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-error-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-warning-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              '& .MuiTouchRipple-root': {
                width: '59.637px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-success-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background:
                  'var(--mui-palette-common-white_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-secondary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-error-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-warning-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              '& .MuiTouchRipple-root': {
                width: '48.593px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-success-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background:
                  'var(--mui-palette-common-white_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-secondary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-error-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-warning-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              '& .MuiTouchRipple-root': {
                width: '47.857px',
                height: '46.286px',
                borderRadius: '100px',
                background: 'var(--mui-palette-success-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '6px 8px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background:
                  'var(--mui-palette-common-white_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-secondary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-error-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-warning-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 173.333% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              '& .MuiTouchRipple-root': {
                width: '55.22px',
                height: '54px',
                borderRadius: '100px',
                background: 'var(--mui-palette-success-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              padding: '8px 11px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              border: '0px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textPrimary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              display: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textInherit': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-text-primary)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-text-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-text-primary)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textInherit (white)': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background:
                  'var(--mui-palette-common-white_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textSecondary': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-secondary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textError': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-error-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textWarning': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-warning-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textInfo': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-primary-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textSuccess': {
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 169.231% */',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              '& .MuiTouchRipple-root': {
                width: '41.231px',
                height: '38.571px',
                borderRadius: '100px',
                background: 'var(--mui-palette-success-_states-focusVisible)',
                padding: '0',
                opacity: '1',
                boxShadow: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
            },
          },
        },
      },
    },
  },
})

export default theme
