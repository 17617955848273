import { ReactComponent as CloseIcon } from '@zendeskgarden/svg-icons/src/16/x-fill.svg'
import React from 'react'

const DeleteProgramPlanModal: React.FC<{
  setProgramPlanDeletionId: any
  deleteProgramPlanById: any
  programPlanForDeletion: any
}> = ({
  setProgramPlanDeletionId,
  deleteProgramPlanById,
  programPlanForDeletion,
}) => {
  const { id: programPlanId, name } = programPlanForDeletion
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black/50">
      <div className="w-2/3 bg-white p-8">
        <>
          <div className={'flex items-center justify-between'}>
            <h1 className="grow text-2xl font-semibold">Delete Program Plan</h1>
            <button
              className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              onClick={() => setProgramPlanDeletionId(null)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div>
              Are you sure you want to delete the {<i>{name}</i>} program plan?
            </div>
            <div>
              ⚠️WARNING: Doing so will create all the programs associated with
              it!
            </div>
          </div>
          <div className="flex justify-center p-4">
            <button
              className="w-full rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              onClick={() => deleteProgramPlanById(programPlanId)}
            >
              {' '}
              Delete{' '}
            </button>
          </div>
        </>
      </div>
    </div>
  )
}

export default DeleteProgramPlanModal
