import React from 'react'

type Update = {
  id: number
  dueDate: string
  description: string
  projectName: string
}

// Mock Update data
const updates: Update[] = [
  {
    id: 1,
    dueDate: '2024-05-20',
    description: 'new project started',
    projectName: 'Project A',
  },
  {
    id: 2,
    dueDate: '2024-05-21',
    description: 'Wireframing_printout.pdf added',
    projectName: 'Project B',
  },
  {
    id: 3,
    dueDate: '2024-05-22',
    description: 'UI cutouts added',
    projectName: 'Project C',
  },
]

const UpdatesList = () => {
  return (
    <>
      <div className="flex items-center justify-between p-8">
        <div className="text-2xl font-semibold">Updates</div>
      </div>
      <div className="flex flex-col">
        {updates.map((update) => {
          const { id, description, dueDate } = update
          return (
            <div key={id} className="flex">
              <div> {dueDate} </div>
              <div> {description} </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default UpdatesList
