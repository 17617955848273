import React, { useEffect, useState } from 'react'
import '@/assets/styles/App.css'
import { useParams } from 'react-router-dom'

import { PageLayout } from '@/components/page-layouts/page-layout'
import ProjectCollaboratorsList from '@/components/ui/collaborator-lists/project-collaborators-list'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import ChatMenu from '@/features/chat/chat-menu'
import FilesList from '@/features/files/files-list'
import NotesList from '@/features/notes/notes-list'
import TaskList from '@/features/tasks/task-list'
import UpdatesList from '@/features/updates/updates-list'
import { useApiContext } from '@/lib/api-context'
import { SocketProvider } from '@/lib/socket-provider'
import { Project } from '@/types/types'

export const ProjectPage = () => {
  return (
    <PageLayout>
      <SocketProvider>
        <ProjectContent />
      </SocketProvider>
    </PageLayout>
  )
}

const ProjectContent = () => {
  const { projectId } = useParams()
  const { apiGet } = useApiContext()
  const [project, setProject] = useState<Project | null>()
  const [researchers, setResearchers] = useState<Project['researchers'] | null>(
    null,
  )

  useEffect(() => {
    apiGet(`projects/${projectId}/`).then((project: Project) => {
      setProject(project)
      setResearchers(project.researchers)
    })
  }, [projectId, apiGet])

  if (!(project && researchers)) {
    return <PageAnchorLoader />
  }

  return (
    <>
      <ChatMenu projectId={project.id} messageHistory={project.messages} />
      <div className="flex flex-1 items-center justify-center bg-gray-200 ">
        <div className="size-full flex-col rounded-md bg-white p-8 shadow-md md:w-2/3">
          <>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-6 lg:grid-cols-6">
              <div className="col-span-3 mb-4 bg-white p-4 md:col-span-3">
                <label className="flex cursor-pointer items-center space-x-2 pb-4">
                  <span className="pr-4 text-2xl font-semibold">
                    {project.name}
                  </span>
                </label>
                <ProjectCollaboratorsList
                  researchers={researchers}
                  setResearchers={setResearchers}
                  projectId={project.id.toString()}
                ></ProjectCollaboratorsList>
              </div>
              <div className="col-span-4 mb-4 rounded border bg-white p-4  shadow">
                <TaskList
                  availableResearchers={researchers}
                  projectId={project.id}
                  initTasks={project.tasks}
                />
              </div>
              <div className="col-span-4 mb-4 rounded border bg-white p-4 shadow  md:col-span-2">
                <NotesList
                  externalRequestUrl={`notes/?project-id=${project.id}`}
                  projectId={project.id}
                />
              </div>
              <div className="col-span-4 mb-4 rounded border bg-white p-4 shadow  md:col-span-4">
                <FilesList
                  initialFiles={project.files}
                  projectId={project.id.toString()}
                />
              </div>
              <div className="col-span-4 mb-4 rounded border bg-white p-4 shadow  md:col-span-2">
                <UpdatesList />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  )
}
