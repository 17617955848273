import React, { FC, useState } from 'react'

import { Annotation as AnnotationType } from '@/types/types'

const Annotation: FC<{
  annotation: AnnotationType
}> = ({ annotation }) => {
  const { researcher, sections } = annotation
  const [hover, setHover] = useState(false)

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      {hover && (
        <div
          style={{
            top: sections[0]?.top + 10,
            left: sections[0]?.left,
            backgroundColor: 'gray',
            position: 'absolute',
            zIndex: 10000,
          }}
        >
          {researcher.name}
        </div>
      )}
      {sections.map((section, index) => {
        const { top, left, width, height } = section
        return (
          <div
            key={index}
            style={{
              top,
              left,
              width,
              height,
              position: 'absolute',
              backgroundColor: 'rgb(255, 255, 0, 0.5)',
              zIndex: 100,
            }}
          ></div>
        )
      })}
    </div>
  )
}

export default Annotation
