import { ReactComponent as ProgramIcon } from '@zendeskgarden/svg-icons/src/16/user-group-stroke.svg'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import KebabMenu from '@/components/menus/kebab-menu'
import SmallProfileIcon from '@/components/small-profile-icon'
import { useAppData } from '@/lib/data-provider'

const ProgramLink: React.FC<{ program: any; isSupervisory: boolean }> = ({
  program,
  isSupervisory,
}) => {
  const { supervisors, student, id } = program

  return (
    <Link
      to={`/program/${id}`}
      key={program.id}
      className={`max-w-xs cursor-pointer rounded-md bg-white p-4 shadow-md hover:bg-gray-100 focus:outline-none ${!isSupervisory ? 'border-2 border-blue-500' : ''}`}
    >
      <div>
        <ProgramIcon />
        <h3 className="mb-2 text-lg font-semibold">{program.name}</h3>
      </div>
      <div className="mt-2 flex">
        <SmallProfileIcon firstName={student.firstName} />
        {supervisors.map((supervisor: any) => (
          <SmallProfileIcon
            key={supervisor.id}
            firstName={supervisor.firstName}
          />
        ))}
      </div>
    </Link>
  )
}

const HomeProgramList = () => {
  const {
    researcher: { supervisingPrograms, programs },
  } = useAppData()
  const navigate = useNavigate()
  const options = [
    {
      label: 'NewProgram Plans Page',
      onClick: () => navigate('/program-plans'),
    },
  ]

  return (
    <>
      <div className="flex items-center pb-4">
        <h2 className="w-full pb-4 text-xl font-semibold">Programs</h2>
        <KebabMenu options={options} />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {programs &&
          programs.map((program: any, index: any) => (
            <ProgramLink key={index} program={program} isSupervisory={false} />
          ))}{' '}
        {supervisingPrograms &&
          supervisingPrograms.map((program: any, index: any) => (
            <ProgramLink key={index} program={program} isSupervisory={true} />
          ))}{' '}
      </div>
    </>
  )
}

export default HomeProgramList
