import React from 'react'
import { FaAnchor } from 'react-icons/fa'
import { ThreeDots } from 'react-loader-spinner'

export const DotsLoader = () => (
  <div className={'flex justify-center'}>
    <ThreeDots visible={true} color="grey" ariaLabel="three-dots-loading" />
  </div>
)

export const PageAnchorLoader = () => (
  <div className=" flex h-screen w-screen items-center justify-center">
    <FaAnchor size={'400px'} />
  </div>
)
