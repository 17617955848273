import React from 'react'

import { PageLayout } from '@/components/page-layouts/page-layout'
import { NoteContent } from '@/features/notes/note'

export const Note = () => {
  return (
    <PageLayout>
      <NoteContent />
    </PageLayout>
  )
}
