import { ReactComponent as FileIcon } from '@zendeskgarden/svg-icons/src/16/file-generic-stroke.svg'
import { ReactComponent as FolderIcon } from '@zendeskgarden/svg-icons/src/16/folder-open-stroke.svg'
import React from 'react'
import { BsTrash } from 'react-icons/bs'
import { HiDotsHorizontal } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import StandardAlertDialog from '@/components/ui/alert-dialog/standard-alert-dialog'
import { FolderType } from '@/types/frontend'
import { File as ServerFile } from '@/types/types'

const Folder: React.FC<{
  currentFolder: FolderType
  addToPath: (folderName: string | null) => void
  removeFromPath: () => void
  deleteFile: (fileId: ServerFile['id']) => Promise<void>
}> = ({ currentFolder, addToPath, removeFromPath, deleteFile }) => {
  const { name, folders, files, parentFolder } = currentFolder
  return (
    <div>
      {parentFolder && (
        <div className="flex items-center">
          <button className="w-full" onClick={() => removeFromPath()}>
            <div className="flex items-center">
              <div className="mr-4">
                <HiDotsHorizontal className="size-5" />
              </div>
              <div> {name} </div>
            </div>
          </button>
        </div>
      )}
      {Array.from(folders.values()).map((folder, idx) => (
        <div key={idx} className="flex items-center">
          <button
            className="w-full"
            key={idx}
            onClick={() => addToPath(folder.name)}
          >
            <div className="flex items-center">
              <div className="mr-4">
                <FolderIcon />
              </div>
              <div> {folder.name} </div>
            </div>
          </button>
        </div>
      ))}
      {Array.from(files)
        .filter((file) => !!file.name)
        .map((file) => (
          <div key={file.id} className=" flex w-full">
            <Link to={`/file/${file.id}`}>
              <div className="flex items-center">
                <div className="mr-4">
                  <FileIcon />
                </div>
                <div> {file.name} </div>
              </div>
            </Link>
            <StandardAlertDialog
              title={'Are you sure?'}
              message={'Deleting this file cannot be undone'}
              onCancel={() => {}}
              onConfirm={() => deleteFile(file.id)}
              button={
                <button className="ml-1 rounded bg-white px-4 py-2 font-bold hover:bg-red-700 hover:text-white">
                  <BsTrash />
                </button>
              }
            />
          </div>
        ))}
    </div>
  )
}

export default Folder
