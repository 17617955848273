import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { ulid } from 'ulid'

import { PageLayout } from '@/components/page-layouts/page-layout'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import { useApiContext } from '@/lib/api-context'
import { Researcher } from '@/types/types'

export const NewProgram = () => {
  return (
    <PageLayout>
      <NewProgramPageContent />
    </PageLayout>
  )
}

const NewProgramPageContent: React.FC<object> = () => {
  const { programPlanId } = useParams()
  const navigate = useNavigate()
  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | null
  >(null) // State for error message
  const [savingProgram, setSavingProgram] = useState<boolean>(false) // State for error message
  const [allResearchers, setAllResearchers] = useState<Researcher[] | null>(
    null,
  )
  const { apiPost, apiGet } = useApiContext()

  const [name, setName] = useState('')
  const [studentEmail, setStudentEmail] = useState('')
  const [supervisors, setSupervisors] = useState<{ label: any; value: any }[]>(
    [],
  )

  useEffect(() => {
    apiGet('researchers/?fields=id,email,name').then((researchers) =>
      setAllResearchers(researchers),
    )
  }, [apiGet])

  const supervisorOptions = useMemo(() => {
    const validSupervisors = allResearchers?.filter(
      (researcher: any) => researcher.email !== studentEmail,
    )

    return validSupervisors?.map((researcher: any) => {
      return {
        label: researcher.email,
        value: researcher.id,
      }
    })
  }, [allResearchers, studentEmail])

  const handleSelectStudent = (studentEmail: string) => {
    setStudentEmail(studentEmail)
    setSupervisors(
      supervisors.filter(
        (supervisor: any) => supervisor.label !== studentEmail,
      ),
    )
  }

  const navigateToProgramPlan = () =>
    navigate(`/program-plans/${programPlanId}`)

  const validateAndSaveProgram = async () => {
    if (!(name && studentEmail && supervisors.length)) {
      return setValidationErrorMessage('All required fields must be filled!')
    }
    setValidationErrorMessage(null)
    setSavingProgram(true)
    const supervisorIds = supervisors.map((supervisor) => supervisor.value)
    try {
      await apiPost('programs/', {
        id: ulid(),
        name,
        student_email: studentEmail,
        supervisorIds,
        programPlan: programPlanId,
      })
    } catch (e) {
      alert('There was an error in adding the program, try again later.')
      console.log('e:', e)
    }
    navigateToProgramPlan()
    setSavingProgram(false)
  }

  if (savingProgram || !allResearchers) {
    return <PageAnchorLoader />
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="flex h-screen justify-center bg-gray-200">
        <div className="w-full rounded-md bg-white p-8 shadow-md md:w-2/3">
          <div className="mb-4 text-xl font-semibold">Add Program</div>
          <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow md:col-span-2">
            <div className="mb-4">
              <label htmlFor="name" className="font-bold">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="studentEmail" className="font-bold">
                Student
              </label>
              <select
                id="studentEmail"
                name="studentEmail"
                value={studentEmail}
                onChange={(e) => handleSelectStudent(e.target.value)}
                className="mt-1 w-full rounded-md border border-gray-300 p-2"
              >
                <option value={undefined}></option>
                {allResearchers.map((researcher: any) => {
                  const { id, email } = researcher
                  return (
                    <option key={id} value={email}>
                      {' '}
                      {researcher.email}{' '}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="supervisorEmails" className="mb-20 font-bold">
                Supervisors
              </label>
              <Select
                id="supervisorEmails"
                required={true}
                options={supervisorOptions}
                onChange={(supervisors: any) => setSupervisors(supervisors)}
                value={supervisors}
                className="mt-2"
                isMulti
              />
            </div>
          </div>
          <button
            className="mb-4 rounded-md bg-blue-500 px-4 py-2 text-white"
            onClick={validateAndSaveProgram}
            type="submit"
          >
            Save
          </button>
          <button
            type="button"
            onClick={navigateToProgramPlan}
            className="ml-2 rounded-md bg-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-500"
          >
            Cancel
          </button>

          {validationErrorMessage && (
            <div className="mb-4 rounded border border-red-400 bg-red-100 px-4 py-2 text-red-700">
              {validationErrorMessage}
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
