import React from 'react'
import { BsTrash } from 'react-icons/bs'

const ProgramTable: React.FC<{
  programs: any[]
  setProgramDeletionId: any
}> = ({ programs, setProgramDeletionId }) => {
  return (
    <div className="flex flex-col">
      {programs.map((program: any) => {
        const { id, name, student, supervisors } = program
        return (
          <div key={id} className="flex">
            <div>{name}</div>
            <div>{student.email}</div>
            <div className={'flex flex-col'}>
              {supervisors.map((supervisor: any) => {
                return <div key={supervisor.id}>{supervisor.email}</div>
              })}
            </div>
            <button
              className="rounded bg-white p-1 font-bold hover:bg-red-700 hover:text-white"
              onClick={() => setProgramDeletionId(id)}
            >
              <BsTrash />
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default ProgramTable
