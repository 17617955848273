import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'

import { PageAnchorLoader } from '@/components/ui/loading/loading'

interface AuthenticationGuardProps {
  component: React.ComponentType
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="">
        <PageAnchorLoader />
      </div>
    ),
  })

  return <Component />
}
