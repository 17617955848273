import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PageLayout } from '@/components/page-layouts/page-layout'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import DeleteProgramPlanModal from '@/features/program-plans/delete-program-plan-modal'
import ProgramPlanTable from '@/features/program-plans/program-plan-table'
import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { ProgramPlan as ProgramPlanType } from '@/types/types'

export const ProgramPlans = () => {
  return (
    <PageLayout>
      <ProgramPlansContent />
    </PageLayout>
  )
}

const ProgramPlansContent = () => {
  const { researcher } = useAppData()
  const navigate = useNavigate()
  const { apiGet, apiDelete } = useApiContext()
  const [programPlansLoading, setProgramPlansLoading] = useState(false)
  const [programPlans, setProgramPlans] = useState<ProgramPlanType[] | null>(
    null,
  )
  const [programPlanDeletionId, setProgramPlanDeletionId] = useState<
    ProgramPlanType['id'] | null
  >(null)

  const requestAndSetProgramPlans = useCallback(
    () =>
      apiGet(`program-plans/?admin-id=${researcher.id}`).then((response) => {
        setProgramPlans(response)
      }),
    [apiGet, researcher.id],
  )

  useEffect(() => {
    setProgramPlansLoading(true)
    requestAndSetProgramPlans().then(() => setProgramPlansLoading(false))
  }, [apiGet, setProgramPlans, researcher.id, requestAndSetProgramPlans])

  const deleteProgramPlanById = async (programPlanId: number) => {
    setProgramPlansLoading(true)
    try {
      await apiDelete(`program-plans/${programPlanId}/`)
    } catch (e) {
      alert('There was an error in deleting the program plan, try again later.')
      console.log('e:', e)
    }
    await requestAndSetProgramPlans()
    setProgramPlanDeletionId(null)
    setProgramPlansLoading(false)
  }

  if (programPlansLoading || !programPlans) {
    return <PageAnchorLoader />
  }

  const addNewProgramPlan = () => {
    navigate('/program-plans/new')
  }

  return (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <div className="size-full rounded-md bg-white p-8 shadow-md md:w-2/3">
        <div className="mb-4 flex items-center justify-between">
          <div className="text-2xl font-semibold">Program Plans</div>
          <button
            className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white"
            onClick={addNewProgramPlan}
          >
            <PlusIcon />{' '}
          </button>
        </div>
        <div className="flex justify-center">
          <ProgramPlanTable
            programPlans={programPlans}
            setProgramPlanDeletionId={setProgramPlanDeletionId}
          />
        </div>
        {programPlanDeletionId && (
          <DeleteProgramPlanModal
            setProgramPlanDeletionId={setProgramPlanDeletionId}
            deleteProgramPlanById={deleteProgramPlanById}
            programPlanForDeletion={programPlans?.find(
              (program) => program.id === programPlanDeletionId,
            )}
          />
        )}
      </div>
    </div>
  )
}

export default ProgramPlans
