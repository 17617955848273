import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { postProtectedResource } from '@/lib/api-service'

export const EmailVerification = () => {
  const { uid, token } = useParams()
  const { getAccessTokenSilently } = useAuth0()
  const [verificationStatus, setVerificationStatus] = useState('Verifying...')

  useEffect(() => {
    // Send API request to verify email
    // Make a request to your Django backend for registration

    const activateUser = async () => {
      setVerificationStatus('Verifying...')
      try {
        const accessToken = await getAccessTokenSilently()
        await postProtectedResource(accessToken, 'auth/users/activation/', {
          uid,
          token,
        })
        // Handle success (e.g., redirect user to success page)
        setVerificationStatus('Verification Successful!')
      } catch (error) {
        setVerificationStatus('Activation failed. Please try again.')
      }
    }

    activateUser()
  }, [uid, token, getAccessTokenSilently])

  return (
    <div>
      <h2>Email Verification</h2>
      <p>{verificationStatus}</p>
    </div>
  )
}
