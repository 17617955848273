import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CardHeader,
  IconButton,
} from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

interface NoteCardProps {
  title: string
  content: string
  date: string
  topBorderColor: string
  onDelete: () => void
  onEdit: () => void
}

const NoteCard: React.FC<NoteCardProps> = ({
  title,
  content,
  date,
  topBorderColor,
  onDelete,
  onEdit,
}) => {
  return (
    <Card sx={{ position: 'relative' }}>
      <Box
        sx={{
          height: '4px',
          backgroundColor: topBorderColor,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <CardHeader
        action={
          <>
            <IconButton aria-label="edit" onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        }
        title={title}
        subheader={date}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onEdit}>
          Edit
        </Button>
        <Button size="small" onClick={onDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  )
}

export default NoteCard
