import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import Modal from 'react-modal'

import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { PartialResearcher, Project, Researcher } from '@/types/types'

const ProjectCollaboratorsList: React.FC<{
  researchers: Project['researchers']
  setResearchers: React.Dispatch<
    React.SetStateAction<PartialResearcher[] | null>
  >
  projectId: any
}> = ({ researchers, setResearchers, projectId }) => {
  const { allResearchers } = useAppData()
  const { apiPatch } = useApiContext()
  const [addModelOpen, setAddModelOpen] = useState(false)
  const [newResearcher, setNewResearcher] = useState<
    undefined | PartialResearcher
  >(undefined)

  const availableResearchers = useMemo(() => {
    return _.differenceBy(allResearchers, researchers, 'id')
  }, [allResearchers, researchers])

  const selectResearcher = (newResearcherId: Researcher['id']) => {
    setNewResearcher(
      allResearchers.find((researcher) => researcher.id === newResearcherId),
    )
  }

  const addResearcher = () => {
    if (!newResearcher) return
    const updatedResearchers = [...researchers, newResearcher]
    try {
      apiPatch(`projects/${projectId}/`, {
        researcherIds: updatedResearchers.map((researcher) => researcher.id),
      })
      setResearchers(updatedResearchers)
    } catch (e) {
      alert('There was an error in adding a researcher, try again later.')
      console.log('e:', e)
    }
    setAddModelOpen(false)
    setNewResearcher(undefined)
  }

  const handleCancel = () => {
    setNewResearcher(undefined)
    setAddModelOpen(false)
  }

  return (
    <div className="flex flex-wrap gap-2">
      <>
        {researchers.map((researcher) => (
          <div
            key={researcher.id}
            className="flex items-center rounded-full bg-gray-200 px-3 py-1 text-sm text-gray-800"
          >
            {researcher.name}
          </div>
        ))}
        <button
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={() => setAddModelOpen(true)}
        >
          {' '}
          <PlusIcon />{' '}
        </button>{' '}
        <Modal
          isOpen={addModelOpen}
          contentLabel="Add Researcher Modal"
          overlayClassName="overlay"
        >
          <div className="absolute left-1/2 top-1/2 w-2/3 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white p-4 shadow-md md:w-1/4">
            <h2 className="mb-4 text-2xl font-semibold">Add Researcher</h2>
            <div>
              <div className="mb-4">
                <label
                  htmlFor="researcherId"
                  className="block text-sm font-medium text-gray-700"
                >
                  {' '}
                  Researcher:{' '}
                </label>
                <select
                  id="researcherId"
                  name="researcherId"
                  value={newResearcher?.id}
                  onChange={(e) => {
                    selectResearcher(e.target.value)
                  }}
                  className="mt-1 w-full rounded-md border p-2"
                >
                  <option value={undefined}>-- Please select --</option>
                  {availableResearchers?.map((researcher) => {
                    return (
                      <option key={researcher.id} value={researcher.id}>
                        {researcher.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="flex justify-end">
                {newResearcher && (
                  <button
                    type="submit"
                    className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
                    onClick={addResearcher}
                  >
                    Add
                  </button>
                )}
                <button
                  type="button"
                  onClick={handleCancel}
                  className="rounded-md bg-gray-300 px-4 py-2 text-gray-700"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </div>
  )
}

export default ProjectCollaboratorsList
