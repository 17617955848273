import { ReactComponent as CloseIcon } from '@zendeskgarden/svg-icons/src/16/x-fill.svg'
import React, { ChangeEvent, SetStateAction, Dispatch, FC } from 'react'
import Modal from 'react-modal'

import { DotsLoader } from '@/components/ui/loading/loading'

const AddFileModal: FC<{
  addModalOpen: boolean
  setAddModalOpen: Dispatch<SetStateAction<boolean>>
  isUploadingFile: boolean
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleUpload: () => Promise<void>
}> = ({
  addModalOpen,
  setAddModalOpen,
  isUploadingFile,
  handleFileChange,
  handleUpload,
}) => {
  return (
    <Modal
      isOpen={addModalOpen}
      contentLabel="Add File Modal"
      overlayClassName="overlay"
    >
      <div className="absolute left-1/2 top-1/2 w-1/4 -translate-x-1/2 -translate-y-1/2 rounded-md border-4 border-gray-400 bg-gray-100 p-4 shadow-md">
        <div className="flex justify-between">
          <h2 className="mb-4 text-2xl font-semibold">Add PDF File</h2>
          <button
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            onClick={() => setAddModalOpen(false)}
          >
            {' '}
            <CloseIcon />{' '}
          </button>
        </div>
        {isUploadingFile ? (
          <DotsLoader />
        ) : (
          <>
            {' '}
            <div className="flex justify-center p-4">
              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </div>
            <div className="flex justify-center p-4">
              <button
                className="w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                onClick={handleUpload}
              >
                {' '}
                Upload{' '}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default AddFileModal
