import React, { useEffect, useRef, useState } from 'react'
import { Document as PdfDocument, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useParams } from 'react-router-dom'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { ulid } from 'ulid'

import Annotation from '@/app/routes/annotation'
import { PageLayout } from '@/components/page-layouts/page-layout'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { Annotation as AnnotationType } from '@/types/types'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export const FilePage = () => {
  return (
    <PageLayout>
      <FileContent />
    </PageLayout>
  )
}
export const FileContent = () => {
  const { researcher } = useAppData()
  const { fileId } = useParams()
  const [file, setFile] = useState(null)
  const [annotations, setAnnotations] = useState<AnnotationType[]>([])
  const inputRef = useRef(null)
  const [numPages, setNumPages] = useState<null | number>(null)
  const [isModeEditable, setModeEditable] = useState<boolean>(false)
  const { apiPost, apiGet } = useApiContext()

  useEffect(() => {
    apiGet(`files/${fileId}`).then((file) => {
      setFile(file)
      setAnnotations(file.annotations)
    })
  }, [apiGet, fileId])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const highlightSection = () => {
    const range = window?.getSelection()?.getRangeAt(0)

    if (!(isModeEditable && inputRef.current && range)) return

    const offsetElement: Element = inputRef.current
    const offsetBoundingRect = offsetElement.getBoundingClientRect()
    const clientRects = Array.from(range.getClientRects())
    const sections = []

    for (const rect of clientRects) {
      sections.push({
        top: Math.round(rect.top) - offsetBoundingRect.top,
        left: Math.round(rect.left) - offsetBoundingRect.left,
        width: Math.round(rect.width),
        height: Math.round(rect.height),
      })
    }

    if (sections.length === 0) return

    const annotation: AnnotationType = {
      id: ulid(),
      researcherId: researcher.id,
      researcher,
      fileId: fileId,
      sections,
    }

    // Send off annotation to backend
    apiPost('annotations/', annotation)
    setAnnotations([...annotations, annotation])
  }

  if (!file) {
    return <PageAnchorLoader />
  }

  const { file: fileUrl } = file

  return (
    <>
      <div className="fixed z-10 flex w-full flex-row-reverse">
        <div className="flex p-16">
          <Toggle
            id="edit-toggle"
            defaultChecked={isModeEditable}
            onChange={() => setModeEditable(!isModeEditable)}
          />
          <label className="pl-1" htmlFor="edit-toggle">
            Edit
          </label>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        {
          // numPages signifies whether the pdf document has loaded because
          // the highlights should only show after the document shows.
          numPages &&
            annotations.map((annotation, index) => {
              if (!(annotation.sections.length > 0)) return <></>
              return <Annotation annotation={annotation} key={index} />
            })
        }
        <PdfDocument
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          inputRef={inputRef}
          onMouseUp={highlightSection}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </PdfDocument>
      </div>
    </>
  )
}
