import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import React, { createContext, useContext, ReactNode } from 'react'

export enum HttpMethod {
  POST = 'POST',
  PATCH = 'PATCH',
}

type MutateMethod = HttpMethod.POST | HttpMethod.PATCH

type Subset<T> = { [K in keyof T]?: T[K] }

export function rollbackEntityCreation<T extends { id: string }>(
  id: T['id'],
  setEntities: React.Dispatch<React.SetStateAction<T[]>>,
  setUpdatingEntityId: React.Dispatch<React.SetStateAction<T['id'] | null>>,
): void {
  setUpdatingEntityId(null)
  setEntities((prevEntities: T[]) => {
    return prevEntities.filter((entity) => entity.id !== id)
  })
}

export function rollbackEntityUpdate<T extends { id: string }>(
  originalEntity: T,
  setEntities: React.Dispatch<React.SetStateAction<T[]>>,
  setUpdatingEntityId: React.Dispatch<React.SetStateAction<T['id'] | null>>,
): void {
  setUpdatingEntityId(null)
  setEntities((entities: T[]) =>
    entities.map((entity) =>
      entity.id === originalEntity.id ? originalEntity : entity,
    ),
  )
}

interface ApiContextType {
  apiGet: (url: string) => Promise<any>
  apiPost: (url: string, data: any) => Promise<any>
  apiFormDataPost: (url: string, data: any) => Promise<any>
  apiPatch: (url: string, data: any) => Promise<any>
  apiDelete: (url: string) => Promise<any>
  mutate: <T>({
    method,
    endpoint,
    data,
    onFail,
  }: {
    method: MutateMethod
    endpoint: string
    data: Subset<T>
    onFail?: () => void
  }) => Promise<T | null>
}

const ApiContext = createContext<ApiContextType | undefined>(undefined)

interface ApiContextProviderProps {
  children: ReactNode
}

const isProduction = process.env.REACT_APP_ENV === 'production'

export const SERVER_ORIGIN = isProduction
  ? 'https://research-dock-backend.onrender.com'
  : 'http://127.0.0.1:8000'

export const API_SERVER_ORIGIN = `${SERVER_ORIGIN}/api`

export const ApiContextProvider: React.FC<ApiContextProviderProps> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const axiosInstance = axios.create({
    baseURL: API_SERVER_ORIGIN,
    timeout: 30000, // Request timeout in milliseconds
    headers: {
      Accept: '*/*', // Accept all types of content
    },
  })

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      if (config.data) {
        config.data = decamelizeKeys(config.data)
      }
      return config
    },
    (error) => {
      // Handle request errors
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    // Axios middleware to convert all API responses to camelCase
    (response) => {
      if (
        response.data &&
        response.headers['content-type'] === 'application/json'
      ) {
        response.data = camelizeKeys(response.data)
      }
      return response
    },
    // Add a response interceptor to handle errors
    (error) => {
      return Promise.reject(error)
    },
  )

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.data) {
        // Convert camelCase keys to snake_case
        config.data = decamelizeKeys(config.data)
      }
      return config
    },
    (error) => {
      // Handle request errors
      return Promise.reject(error)
    },
  )

  const apiGet = (url: string) =>
    axiosInstance.get(`/${url}`).then((res) => res.data)

  const apiFormDataPost = async (url: string, data: any) => {
    const token = await getAccessTokenSilently()
    return fetch(`${API_SERVER_ORIGIN}/${url}`, {
      method: 'POST',
      body: data,
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => response.json())
  }

  const apiPost = (url: string, data: any) =>
    axiosInstance
      .post(`${API_SERVER_ORIGIN}/${url}`, data)
      .then((res) => res.data)
  const apiPatch = (url: string, data: any) =>
    axiosInstance
      .patch(`${API_SERVER_ORIGIN}/${url}`, data)
      .then((res) => res.data)
  const apiDelete = (url: string) =>
    axiosInstance.delete(`${API_SERVER_ORIGIN}/${url}`).then((res) => res.data)

  async function mutate<T>({
    method,
    endpoint,
    data,
    onFail,
  }: {
    method: MutateMethod
    endpoint: string
    data: Subset<T>
    onFail?: () => void
  }): Promise<T | null> {
    const axiosMutateMethod =
      method === HttpMethod.POST ? axiosInstance.post : axiosInstance.patch
    try {
      const response = await axiosMutateMethod<T>(endpoint, data)
      return response.data
    } catch (e) {
      console.log(e)
      alert('There was an error, try again later.')
      onFail && onFail()
      return null
    }
  }

  const contextValue: ApiContextType = {
    apiGet,
    apiPost,
    apiPatch,
    apiDelete,
    apiFormDataPost,
    mutate,
  }

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  )
}

export const useApiContext = (): ApiContextType => {
  const context = useContext(ApiContext)

  if (!context) {
    throw new Error('useApiContext must be used within an ApiContextProvider')
  }

  return context
}
