import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import MailIcon from '@mui/icons-material/Mail'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import SchoolIcon from '@mui/icons-material/School'
import { Collapse } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import * as React from 'react'
import { ReactNode, useState } from 'react'

import NewAppBar from '@/components/menus/new-app-bar'
import { SecondarySideBar } from '@/components/menus/secondary-side-bar'

const categories = [
  {
    text: 'Pages',
    icon: <LibraryBooksIcon />,
    active: true,
    children: [
      { text: 'Home' },
      { text: 'Projects' },
      { text: 'Programs' },
      { text: 'Calendar' },
    ],
  },
  {
    text: 'Projects',
    icon: <MenuBookIcon />,
    children: [{ text: 'Quantum Computing' }, { text: 'Research Radar' }],
  },
  {
    text: 'Programs',
    icon: <SchoolIcon />,
  },
]

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

interface NewSideBarProps {
  children: ReactNode
}

export const NewSideBar: React.FC<NewSideBarProps> = ({ children }) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({})
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClick = (index: number) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }))
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NewAppBar handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {categories.map(({ text, icon, children }, index) => (
            <ListItem disablePadding sx={{ display: 'block' }} key={index}>
              <ListItemButton
                onClick={() => handleClick(index)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                {open &&
                  children &&
                  (expanded[index] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                {open &&
                  children &&
                  children.map((child, index) => (
                    <ListItem key={index}>
                      <ListItemText>{child.text}</ListItemText>
                    </ListItem>
                  ))}
              </Collapse>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box>
        <DrawerHeader />
        <SecondarySideBar>{children}</SecondarySideBar>
      </Box>
    </Box>
  )
}
