import { ReactComponent as FileIcon } from '@zendeskgarden/svg-icons/src/16/file-generic-stroke.svg'
import { ReactComponent as FolderIcon } from '@zendeskgarden/svg-icons/src/16/folder-open-stroke.svg'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { ulid } from 'ulid'

import { DotsLoader } from '@/components/ui/loading/loading'
import AddFileModal from '@/features/files/add-file-modal'
import AddFolderModal from '@/features/files/add-folder-modal'
import Folder from '@/features/files/folder'
import { useApiContext } from '@/lib/api-context'
import { FolderType } from '@/types/frontend'
import { PartialFile, File as ServerFile } from '@/types/types'

const systemizeFiles = (files: PartialFile[]): FolderType => {
  const globalFolder = {
    name: null,
    parentFolder: null,
    folders: new Map(),
    files: new Set<PartialFile>(),
  }
  files
    .map((file) => [file.path, file] as [string, PartialFile])
    .forEach(([path, fileId]) => {
      const folderNames = path.split('/')
      let currentFolder = globalFolder
      for (const folderName of folderNames) {
        if (folderName.length === 0) {
          continue
        }
        if (!currentFolder.folders.has(folderName)) {
          const newFolder = {
            name: folderName,
            parentFolder: currentFolder,
            folders: new Map(),
            files: new Set<PartialFile>(),
          }

          currentFolder.folders.set(folderName, newFolder)
        }
        currentFolder = currentFolder.folders.get(folderName)
      }
      currentFolder.files.add(fileId)
    })

  return globalFolder
}

const folderFromPath = (
  folder: FolderType,
  pathStack: string[],
): FolderType => {
  if (pathStack.length === 0) {
    return folder
  }
  const childFolderName = pathStack.shift()
  if (!childFolderName) return folder
  const childFolder = folder.folders.get(childFolderName)
  if (!childFolder) return folder

  return folderFromPath(childFolder, pathStack)
}

const FilesList: React.FC<{
  initialFiles: PartialFile[]
  projectId: string
}> = ({ initialFiles, projectId }) => {
  const { apiFormDataPost, apiDelete } = useApiContext()
  const [files, setFiles] = useState(initialFiles)
  const [pathStack, setPathStack] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false)
  const [isUploadingFolder, setIsUploadingFolder] = useState<boolean>(false)

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isUploadingFile, setIsUploadingFile] = useState(false)

  const folders = useMemo(() => {
    return systemizeFiles(files)
  }, [files])
  const currentFolder = useMemo(
    () => folderFromPath(folders, [...pathStack]),
    [pathStack, folders],
  )
  const path = useMemo(() => {
    return pathStack.join('/')
  }, [pathStack])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const addToPath = (folderName: FolderType['name']) => {
    if (!folderName) return
    setPathStack([...pathStack, folderName])
  }

  const removeFromPath = () => {
    setPathStack(pathStack.slice(0, -1))
  }

  const deleteFile = async (fileId: ServerFile['id']) => {
    console.log('fileId:', fileId)
    setIsLoading(true)
    try {
      await apiDelete(`files/${fileId}/`)
      setFiles(files.filter((file) => file.id !== fileId))
    } catch (e) {
      alert('There was an error in deleting the file, try again later.')
      console.log('e:', e)
    }
    setIsLoading(false)
  }

  const handleFolderUpload = async (folderName: string) => {
    const newPath = path.length === 0 ? folderName : `${path}/${folderName}`
    console.log('newPath :', newPath)
    const formData = new FormData()
    formData.append('path', newPath)
    formData.append('project', `${projectId}`)
    formData.append('id', ulid())
    setIsUploadingFolder(true)
    try {
      const newFile = await apiFormDataPost('files/', formData)
      setFiles([...files, newFile])
    } catch (e) {
      alert('There was an error in uploading the file, try again later.')
      console.log('e:', e)
    }
    setIsUploadingFolder(false)
    setAddFolderModalOpen(false)
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload.')
      return
    }

    const formData = new FormData()
    formData.append('name', selectedFile.name)
    formData.append('path', path)
    formData.append('project', `${projectId}`)
    formData.append('file', selectedFile)
    formData.append('id', ulid())
    setIsUploadingFile(true)
    try {
      const newFile = await apiFormDataPost('files/', formData)
      setFiles([...files, newFile])
    } catch (e) {
      alert('There was an error in uploading the file, try again later.')
      console.log('e:', e)
    }
    setIsUploadingFile(false)
    setAddModalOpen(false)
    setSelectedFile(null)
  }

  if (isLoading) {
    return <DotsLoader />
  }

  return (
    <>
      <div className="flex items-center justify-between p-8">
        <div className="text-2xl font-semibold">Files</div>
        <div className="flex">
          <button
            className="mr-1 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            onClick={() => setAddModalOpen(true)}
          >
            {' '}
            <FileIcon />{' '}
          </button>{' '}
          <button
            className="ml-1 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            onClick={() => setAddFolderModalOpen(true)}
          >
            {' '}
            <FolderIcon />{' '}
          </button>{' '}
        </div>
      </div>
      <div>
        <Folder
          currentFolder={currentFolder}
          addToPath={addToPath}
          removeFromPath={removeFromPath}
          deleteFile={deleteFile}
        />
      </div>
      <AddFolderModal
        addFolderModalOpen={addFolderModalOpen}
        setAddFolderModalOpen={setAddFolderModalOpen}
        isUploadingFolder={isUploadingFolder}
        handleFolderUpload={handleFolderUpload}
        siblingFolderNames={new Set(Array.from(currentFolder.folders.keys()))}
      />

      <AddFileModal
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        isUploadingFile={isUploadingFile}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
      />
    </>
  )
}

export default FilesList
