import { Avatar } from '@mui/material'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import * as React from 'react'

interface ChipData {
  key: number
  label: string
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export default function ChipsArray() {
  const [chipData, setChipData] = React.useState<readonly ChipData[]>([
    { key: 0, label: 'Max' },
    { key: 1, label: 'Maiyuren' },
    { key: 2, label: 'Malavan' },
  ])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key),
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map((data) => {
        return (
          <ListItem key={data.key}>
            <Chip
              avatar={<Avatar src="/static/images/avatar/1.jpg" />}
              label={data.label}
            />
          </ListItem>
        )
      })}
    </Box>
  )
}
