import { ReactComponent as UserIcon } from '@zendeskgarden/svg-icons/src/16/user-circle-stroke.svg'
import React from 'react'

interface CollaboratorsListItemProps {
  name: string
  subtext: string
}

const CollaboratorsListItem: React.FC<CollaboratorsListItemProps> = ({
  name,
  subtext,
}) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-300 p-4">
      <div className="flex items-center">
        <UserIcon className="m-4" />
        <div>
          <span className="font-semibold">{name}</span>
          <p className="text-gray-500">{subtext}</p>
        </div>
      </div>
    </div>
  )
}

export default CollaboratorsListItem
