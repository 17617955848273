import React from 'react'

import '@/assets/styles/App.css'
import { PageLayout } from '@/components/page-layouts/page-layout'
import RightColumn from '@/components/page-layouts/right-column'
import ProfileSummary from '@/features/profile/profile-summary'

export const Profile = () => {
  return (
    <PageLayout>
      <div className="flex h-screen items-center justify-center bg-gray-200">
        <div className="size-full rounded-md bg-white p-8 shadow-md md:w-2/3">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 lg:grid-cols-4">
            <div className="col-span-4 p-4 md:col-span-2">
              <ProfileSummary />
            </div>
            <RightColumn />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
