import React from 'react'
import { BsTrash } from 'react-icons/bs'

const MilestonesTable: React.FC<{
  milestones: any[]
  removeMilestone: any
}> = ({ milestones, removeMilestone }) => {
  return (
    <div className="flex flex-col">
      {milestones.map((milestone: any, index) => {
        const { id, name, description, dueDate } = milestone
        return (
          <div key={id} className="flex">
            <div>{name}</div>
            <div>{description}</div>
            <div>{dueDate}</div>
            <button
              className="rounded bg-white p-1 font-bold hover:bg-red-700 hover:text-white"
              onClick={() => removeMilestone(index)}
            >
              <BsTrash />
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default MilestonesTable
