import { Editor } from '@tinymce/tinymce-react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { PageAnchorLoader } from '@/components/ui/loading/loading'
import { useApiContext } from '@/lib/api-context'
import { Note as NoteType } from '@/types/types'

export const NoteContent = () => {
  const newNote: boolean = useLocation().state?.newNote
  const { noteId } = useParams()
  const { apiPatch, apiGet } = useApiContext()

  const [note, setNote] = useState<NoteType | null>(null)
  const [title, setTitle] = useState('Untitled')
  const [content, setContent] = useState('')

  useEffect(() => {
    if (newNote) return
    apiGet(`notes/${noteId}`).then((note) => setNote(note))
  }, [apiGet, newNote, noteId])

  useEffect(() => {
    if (!note) return
    setTitle(note.title)
    setContent(note.content)
  }, [note])

  const handleTitleChange = (event: any) => {
    const newTitle = event.target.value
    setTitle(newTitle)
    debouncedModifyNote(newTitle, content)
  }

  const handleContentChange = (newContent: string) => {
    setContent(newContent)
    debouncedModifyNote(title, newContent)
  }

  const debouncedModifyNote = _.debounce((title, content) => {
    try {
      apiPatch(`notes/${noteId}/`, { title, content })
    } catch (e) {
      alert('The update to the note has failed. Try again later.')
      console.log('e:', e)
    }
  }, 1000)

  if (!newNote && !note) {
    return <PageAnchorLoader />
  }

  return (
    <div className="flex size-full flex-col rounded-md bg-white p-8 shadow-md md:w-2/3">
      <input
        type="text"
        value={title}
        onChange={handleTitleChange}
        className="mb-4 mt-2 text-4xl font-extrabold outline-none"
      />
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        initialValue={content}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help',
        }}
        onEditorChange={handleContentChange}
      />
    </div>
  )
}
