import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageLayout } from '@/components/page-layouts/page-layout'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import DeleteProgramModal from '@/features/supervisory-programs/delete-program-modal'
import ProgramTable from '@/features/supervisory-programs/program-table'
import { useApiContext } from '@/lib/api-context'
import { Program, ProgramPlan as ProgramPlanType } from '@/types/types'

export const ProgramPlan = () => {
  return (
    <PageLayout>
      <ProgramPlanContent />
    </PageLayout>
  )
}

const ProgramPlanContent = () => {
  const { apiGet, apiDelete } = useApiContext()
  const { programPlanId } = useParams()
  const navigate = useNavigate()
  const [programPlan, setProgramPlan] = useState<ProgramPlanType | null>(null)
  const [programPlanLoading, setProgramPlanLoading] = useState(false)
  const [programDeletionId, setProgramDeletionId] = useState<
    Program['id'] | null
  >(null)

  const requestAndSetProgramPlan = useCallback(
    () => apiGet(`program-plans/${programPlanId}/`).then(setProgramPlan),
    [apiGet, programPlanId],
  )

  useEffect(() => {
    setProgramPlanLoading(true)
    requestAndSetProgramPlan().then(() => setProgramPlanLoading(false))
  }, [apiGet, programPlanId, requestAndSetProgramPlan])

  const deleteProgramById = async (programId: number) => {
    setProgramPlanLoading(true)
    try {
      await apiDelete(`programs/${programId}/`)
    } catch (e) {
      alert('There was an error in deleting the program, try again later.')
      console.log('e:', e)
    }
    await requestAndSetProgramPlan()
    setProgramDeletionId(null)
    setProgramPlanLoading(false)
  }

  if (programPlanLoading || !programPlan) {
    return <PageAnchorLoader />
  }

  const addNewProgram = () => {
    navigate(`/program-plans/${programPlanId}/program/new`)
  }

  const { programs, name } = programPlan

  return (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <div className="size-full rounded-md bg-white p-8 shadow-md md:w-2/3">
        <div className="mb-4 text-2xl font-semibold">{name}</div>
        <div className="mb-4 rounded border p-4 shadow">
          <div className="mb-4 flex items-center justify-between">
            <div className="text-xl font-semibold">Programs</div>
            <button
              className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white"
              onClick={addNewProgram}
            >
              <PlusIcon />{' '}
            </button>
          </div>
          <div className="flex justify-center">
            <ProgramTable
              programs={programs}
              setProgramDeletionId={setProgramDeletionId}
            />
          </div>
          {programDeletionId && (
            <DeleteProgramModal
              setProgramDeletionId={setProgramDeletionId}
              deleteProgramById={async () => {
                await deleteProgramById(programDeletionId)
                setProgramDeletionId(null)
              }}
              programForDeletion={programs?.find(
                (program: any) => program.id === programDeletionId,
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}
