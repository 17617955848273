import { ReactComponent as CloseIcon } from '@zendeskgarden/svg-icons/src/16/x-fill.svg'
import React from 'react'

const DeleteProgramModal: React.FC<{
  setProgramDeletionId: any
  deleteProgramById: any
  programForDeletion: any
}> = ({ setProgramDeletionId, deleteProgramById, programForDeletion }) => {
  const { id: programId, name } = programForDeletion
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black/50">
      <div className="w-2/3 bg-white p-8">
        <>
          <div className={'flex items-center justify-between'}>
            <h1 className="grow text-2xl font-semibold">Delete Program Plan</h1>
            <button
              className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              onClick={() => setProgramDeletionId(null)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div>
              Are you sure you want to delete the {<i>{name}</i>} program?
            </div>
          </div>
          <div className="flex justify-center p-4">
            <button
              className="w-full rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              onClick={() => deleteProgramById(programId)}
            >
              {' '}
              Delete{' '}
            </button>
          </div>
        </>
      </div>
    </div>
  )
}

export default DeleteProgramModal
