import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import React, { ReactNode } from 'react'

interface PageLayoutProps {
  children: ReactNode
  title: string
}

export const TitleContainer: React.FC<PageLayoutProps> = ({
  children,
  title,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" paddingLeft={2}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={1} sx={{ padding: '20px', marginBottom: '20px' }}>
          {children}{' '}
        </Paper>
      </Grid>
    </Grid>
  )
}
