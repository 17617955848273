import React, { useMemo, useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'

import { Project, Researcher, Task } from '../../types/types'

interface AddTaskModalProps {
  researchers: Project['researchers']
  task: Task
  updateTask: (updatedTask: Task) => void

  finishUpdate: () => void
  createdInProject: boolean
}

interface SelectInputResearcher {
  label: Researcher['name']
  value: Researcher['id']
}

const UpdateTaskModal: React.FC<AddTaskModalProps> = ({
  researchers,
  task,
  updateTask,
  finishUpdate,
  createdInProject,
}) => {
  const assignedResearcherOptions: SelectInputResearcher[] = useMemo(
    () =>
      researchers.map((researcher) => ({
        label: researcher.email,
        value: researcher.id,
      })),
    [researchers],
  )
  const [chosenResearchers, setChosenResearchers] = useState<
    SelectInputResearcher[]
  >(
    task.researchers?.map((id) => ({
      label: researchers.find((researcher) => researcher.id === id)?.name ?? '',
      value: id,
    })) ?? [],
  )

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    updateTask({ ...task, [name]: value })
  }

  const handleResearchersInputChange = (
    selectInputResearchers: SelectInputResearcher[],
  ) => {
    setChosenResearchers(selectInputResearchers)
    const researchers = selectInputResearchers.map(
      (researcher) => researcher.value,
    )
    updateTask({ ...task, researchers })
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={finishUpdate}
      contentLabel="Add Task Modal"
      overlayClassName="overlay"
    >
      <div className="absolute left-1/2 top-1/2 w-2/3 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white p-4 shadow-md md:w-1/4">
        <h2 className="mb-4 text-2xl font-semibold">Add Task</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={task.title ?? ''}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description:
            </label>
            <input
              type="text"
              id="description"
              name="description"
              value={task.description ?? ''}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border p-2"
            />
          </div>
          <div className="mb-4">
            {createdInProject && (
              <label
                htmlFor="researcherIds"
                className="block text-sm font-medium text-gray-700"
              >
                {' '}
                Researchers:{' '}
                <Select
                  required={true}
                  options={assignedResearcherOptions}
                  onChange={(selectInputResearchers: any) =>
                    handleResearchersInputChange(selectInputResearchers)
                  }
                  value={chosenResearchers}
                  isMulti
                />{' '}
              </label>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="dueDate"
              className="block text-sm font-medium text-gray-700"
            >
              Due Date:
            </label>
            <input
              type="date"
              id="dueDate"
              name="dueDate"
              required
              value={task.dueDate || ''}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border p-2"
            />
          </div>
          <button
            type="submit"
            className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            onClick={finishUpdate}
          >
            Done
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default UpdateTaskModal
