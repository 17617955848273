import React from 'react'

import '@/assets/styles/App.css'
import { PageLayout } from '@/components/page-layouts/page-layout'
import NotesList from '@/features/notes/notes-list'
import HomeProjectList from '@/features/projects/home-project-list'
import HomeProgramList from '@/features/supervisory-programs/home-program-list'
import HomeTasksList from '@/features/tasks/home-tasks-list'

export const Home = () => {
  return (
    <PageLayout>
      <div className="flex items-center justify-center bg-gray-200">
        <div className="w-full rounded-md bg-white p-8 shadow-md md:w-2/3">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow md:col-span-2">
              <HomeProjectList />
            </div>
            <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow md:col-span-2">
              <HomeProgramList />
            </div>
            <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow md:col-span-2">
              <HomeTasksList />
            </div>
            <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow md:col-span-2">
              <NotesList />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
