import React, { useCallback, useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { IoMdSend } from 'react-icons/io'
import { ulid } from 'ulid'

import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { useSocket } from '@/lib/socket-provider'
import { Project } from '@/types/types'

import { QuickMessage } from './types/chat-types'

interface ChatComponentProps {
  projectId: Project['id']
  quickMessages: QuickMessage[]
  setQuickMessages: React.Dispatch<React.SetStateAction<QuickMessage[]>>
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  projectId,
  quickMessages,
  setQuickMessages,
}) => {
  const { researcher } = useAppData()
  const [messageInput, setMessageInput] = useState<string>('')
  const { apiPost } = useApiContext()
  const socket = useSocket()

  const setRef = useCallback((node: any) => {
    if (node) {
      node.scrollIntoView({ smooth: true })
    }
  }, [])

  useEffect(() => {
    if (socket) {
      // Join the room
      socket.emit('joinRoom', projectId)

      // Listen for incoming messages
      socket.on('message', (message) => {
        setQuickMessages((prevMessages) => [...prevMessages, message])
      })

      // Clean up the socket connection on unmount
      return () => {
        // Don't disconnect the socket here
        // Remove the socket event listeners instead
        socket.off('message')
      }
    }
  }, [socket, projectId, setQuickMessages])

  const createNewMessage = (newMessageId: string) => {
    try {
      apiPost('messages/', {
        id: newMessageId,
        projectId,
        senderId: researcher.id,
        message: messageInput,
        timestamp: new Date().toISOString(),
      })
    } catch (e) {
      console.log('There was an error in adding the message')
      console.log('e:', e)
    }
  }

  const handleMessageSubmit = () => {
    if (!socket || messageInput.trim() === '') return

    const newMessageId = ulid()

    // Send the message to the backend to be stored
    createNewMessage(newMessageId)
    const { id, email, name } = researcher

    const newQuickMessage: QuickMessage = {
      id: newMessageId,
      sender: { id, email, name },
      message: messageInput,
    }

    // Send the message to the socket server
    socket.emit('message', {
      room: projectId,
      message: newQuickMessage,
      senderSocketId: socket.id,
    })

    setQuickMessages([...quickMessages, newQuickMessage])
    setMessageInput('')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleMessageSubmit()
    }
  }

  if (!socket || !socket.connected) {
    return <div>Connecting...</div> // loading socket connection
  }

  return (
    <div className="flex h-80 w-96 max-w-xl flex-col justify-between overflow-hidden rounded-lg bg-white shadow-xl">
      <div className="flex max-h-full flex-col overflow-hidden overflow-y-auto p-4 ">
        <div>
          {quickMessages.map((quickMessage, index) => {
            const isUserMessage = quickMessage.sender.id === researcher.id
            const isLastMessage = index === quickMessages.length - 1

            return (
              <div
                className={`ml-auto mt-2 flex w-full space-x-3 ${
                  isUserMessage ? 'justify-end' : ''
                }`}
                ref={isLastMessage ? setRef : null}
                key={index}
              >
                {!isUserMessage && (
                  <div className="size-10 shrink-0 rounded-full bg-gray-300">
                    <FaUserCircle className="size-full text-gray-400" />
                  </div>
                )}
                <div>
                  <div
                    className={`${
                      isUserMessage
                        ? 'rounded-l-lg rounded-br-lg bg-blue-600 p-3 text-white'
                        : 'rounded-r-lg rounded-bl-lg bg-gray-300 p-3'
                    } rounded-l-lg rounded-br-lg p-3`}
                    style={{ wordWrap: 'break-word' }} // Inline styles for word wrapping
                  >
                    <p className="text-sm">{quickMessage.message}</p>
                  </div>
                  <span className="text-xs leading-none text-gray-500">
                    {quickMessage.sender.email}
                  </span>
                </div>
                {isUserMessage && (
                  <div className="size-10 shrink-0 rounded-full bg-gray-300">
                    <FaUserCircle className="size-full text-gray-400" />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex items-center bg-gray-300 p-4">
        <input
          className="flex h-10 w-full items-center rounded px-3 text-sm"
          type="text"
          value={messageInput}
          placeholder="Type your message…"
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button
          onClick={handleMessageSubmit}
          className="ml-2 rounded-lg bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600"
        >
          <IoMdSend />
        </button>
      </div>
    </div>
  )
}

export default ChatComponent
