import { Grid } from '@mui/material'
import React from 'react'

import { TitleContainer } from '@/components/page-layouts/title-container' // Assuming this is the path to your ProjectTaskCard component

import ProjectTaskCard from './project-task-card'

const ProjectTaskList: React.FC = () => {
  // Mock data for project tasks
  const projectTasks = [
    {
      id: 1,
      name: 'Task 1',
      dueDate: '2024-08-01',
      status: 'In Progress',
      assignee: 'Alice',
      assignedProject: 'Project A',
      topBorderColor: '#8524f4',
    },
    {
      id: 2,
      name: 'Task 2',
      dueDate: '2024-08-15',
      status: 'Pending',
      assignee: 'Bob',
      assignedProject: 'Project B',
      topBorderColor: '#8524f4',
    },
    {
      id: 3,
      name: 'Task 3',
      dueDate: '2024-07-31',
      status: 'Completed',
      assignee: 'Charlie',
      assignedProject: 'Project C',
      topBorderColor: 'red',
    },
  ]

  return (
    <TitleContainer title="Tasks">
      <Grid container spacing={2}>
        {projectTasks.map((task, index) => (
          <Grid item xs={12} key={index}>
            <ProjectTaskCard
              name={task.name}
              dueDate={task.dueDate}
              status={task.status}
              assignee={task.assignee}
              topBorderColor={task.topBorderColor}
              assignedProject={task.assignedProject}
            />
          </Grid>
        ))}
      </Grid>
    </TitleContainer>
  )
}

export default ProjectTaskList
