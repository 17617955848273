import React from 'react'

export const NotFound: React.FC = () => {
  return (
    <div className="">
      <h1 id="page-title" className="">
        Not Found
      </h1>
    </div>
  )
}
