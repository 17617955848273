import { ReactComponent as ProjectIcon } from '@zendeskgarden/svg-icons/src/26/book.svg'
import React from 'react'
import { Link } from 'react-router-dom'

import KebabMenu from '@/components/menus/kebab-menu'
import SmallProfileIcon from '@/components/small-profile-icon'
import { MenuOption } from '@/types/frontend'
import { PartialProject } from '@/types/types'

const ProgramProjectList: React.FC<{
  projects: PartialProject[]
  options: MenuOption[]
}> = ({ projects, options }) => {
  return (
    <>
      <div className="flex items-center pb-4">
        <h2 className="w-full pb-4 text-xl font-semibold">Projects</h2>
        <KebabMenu options={options} />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
        {projects.map((project) => (
          <Link
            to={`/project/${project.id}`}
            key={project.id}
            className="max-w-xs cursor-pointer rounded-md bg-white p-4 shadow-md hover:bg-gray-100 focus:outline-none"
          >
            <div>
              <ProjectIcon />
              <h3 className="mb-2 text-lg font-semibold">{project.name}</h3>
            </div>
            <div className="mt-2 flex">
              {project.researchers.map((researcher) => {
                const { id, email } = researcher
                return <SmallProfileIcon key={id} firstName={email} />
              })}
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

export default ProgramProjectList
