import ChatIcon from '@mui/icons-material/Chat'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import EditNoteIcon from '@mui/icons-material/EditNote'
import FolderIcon from '@mui/icons-material/Folder'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import * as React from 'react'

const actions = [
  { icon: <ChatIcon />, name: 'Chat' },
  { icon: <FolderIcon />, name: 'Files' },
  { icon: <EditNoteIcon />, name: 'Notes' },
  { icon: <CloudUploadIcon />, name: 'Upload' },
]

export default function SpeedDialMenu() {
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: 'absolute', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
        />
      ))}
    </SpeedDial>
  )
}
