import { AxiosRequestConfig } from 'axios'

import { ApiResponse } from '@/types/api-response'

import { API_SERVER_ORIGIN } from './api-context'
import { callExternalApi } from './external-api.service'

export const getProtectedResource = async (
  accessToken: string,
  endpoint: string,
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_ORIGIN}/${endpoint}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const { data, error } = (await callExternalApi({ config })) as ApiResponse

  return {
    data,
    error,
  }
}

export const postProtectedResource = async (
  accessToken: string,
  endpoint: string,
  body: any,
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_ORIGIN}/${endpoint}/`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  }

  const { data, error } = (await callExternalApi({ config })) as ApiResponse

  return {
    data,
    error,
  }
}
