import classNames from 'classnames'
import React, { useState } from 'react'

import { PartialMilestone } from '@/types/types'

import TimelineModal from './timeline-modal'

const HorizontalTimeline = ({ events }: { events: PartialMilestone[] }) => {
  const sortedEvents = events.sort(
    (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
  )
  const [selectedEvent, setSelectedEvent] = useState<number | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = (clickedEvent: number) => {
    setSelectedEvent(clickedEvent)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    // Logic to show the modal (you can use state or a library like React Modal)
  }

  return (
    <>
      <div className="flex overflow-x-auto p-4 ">
        {sortedEvents.map((event, index) => (
          <button
            key={index}
            className={classNames('timeline-item ', { 'ml-4': index !== 0 })}
            onClick={() => openModal(index)}
          >
            <div className="timeline-content cursor-pointer hover:bg-gray-200">
              <h3>{event.name}</h3>
              <p>{event.dueDate}</p>
            </div>
          </button>
        ))}
      </div>

      {/* Render the modal component */}
      <TimelineModal
        timelineEvents={events}
        selectedEvent={selectedEvent}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  )
}

export default HorizontalTimeline
