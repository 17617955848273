import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg'
import React, { useState } from 'react'
import { ulid } from 'ulid'

import {
  HttpMethod,
  rollbackEntityCreation,
  rollbackEntityUpdate,
  useApiContext,
} from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { PartialResearcher, Project, Task } from '@/types/types'

import TaskListTable from './task-list-table'
import UpdateTaskModal from './update-task-modal'

const TaskList: React.FC<{
  initTasks: Task[]
  availableResearchers: PartialResearcher[]
  projectId?: Project['id']
}> = ({ initTasks, availableResearchers, projectId }) => {
  const { mutate } = useApiContext()
  const { researcher } = useAppData()
  const [tasks, setTasks] = useState<Task[]>(initTasks)
  const [taskId, setTaskId] = useState<Task['id'] | null>(null)

  const createdInProject = !!projectId

  const createTask = () => {
    const newTask = {
      id: ulid(),
      title: null,
      completed: false,
      description: null,
      dueDate: null,
      // Must be associated with either a researcher or a project
      project: createdInProject ? projectId : null,
      researchers: createdInProject ? [] : [researcher.id],
    }
    setTasks([newTask, ...tasks])
    setTaskId(newTask.id)

    mutate<Task>({
      method: HttpMethod.POST,
      endpoint: 'tasks/',
      data: newTask,
      onFail: () =>
        rollbackEntityCreation<Task>(newTask.id, setTasks, setTaskId),
    })
  }

  const updateTask = (updatedTask: Task) => {
    const originalTask = tasks.find((task) => task.id === updatedTask.id)
    if (!originalTask) return

    setTasks(
      tasks.map((task) => (task.id === updatedTask.id ? updatedTask : task)),
    )

    mutate<Task>({
      method: HttpMethod.PATCH,
      endpoint: `tasks/${updatedTask.id}/`,
      data: updatedTask,
      onFail: () =>
        rollbackEntityUpdate<Task>(originalTask, setTasks, setTaskId),
    })
  }

  return (
    <>
      <div className="flex items-center justify-between p-8">
        <div className="text-2xl font-semibold">Tasks</div>
        <button
          className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          onClick={createTask}
        >
          <PlusIcon />{' '}
        </button>
      </div>
      <TaskListTable
        tasks={tasks}
        setTasks={setTasks}
        setUpdatingTaskId={setTaskId}
      />
      {taskId && (
        <UpdateTaskModal
          createdInProject={createdInProject}
          researchers={availableResearchers}
          updateTask={updateTask}
          finishUpdate={() => setTaskId(null)}
          task={tasks.find((task) => task.id === taskId) ?? tasks[0]}
        />
      )}
    </>
  )
}

export default TaskList
