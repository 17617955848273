import React, { useEffect, useState } from 'react'

import { DotsLoader } from '@/components/ui/loading/loading'
import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'
import { Task } from '@/types/types'

import TaskList from './task-list'

const HomeTasksList = () => {
  const { researcher, allResearchers } = useAppData()
  const { apiGet } = useApiContext()
  const [tasks, setTasks] = useState<Task[] | null>(null)

  useEffect(() => {
    apiGet(`tasks/?user-id=${researcher.id}`).then((response) => {
      setTasks(response)
    })
  }, [apiGet, researcher.id])

  return (
    <>
      {!tasks && <DotsLoader />}
      {tasks && (
        <TaskList initTasks={tasks} availableResearchers={allResearchers} />
      )}
    </>
  )
}

export default HomeTasksList
