import { Checkbox } from '@mui/material'
import React from 'react'
import { BsTrash } from 'react-icons/bs'

import { useApiContext } from '@/lib/api-context'

import { Task } from '../../types/types'

const TaskListTable: React.FC<{
  tasks: Task[]
  setTasks: any
  setUpdatingTaskId: React.Dispatch<React.SetStateAction<string | null>>
}> = ({ tasks, setTasks, setUpdatingTaskId }) => {
  const { apiPatch, apiDelete } = useApiContext()

  const handleToggle = (chosenTaskId: Task['id']) => {
    const updatedTasks = [...tasks]
    const updateTask = updatedTasks.find((task) => task.id === chosenTaskId)
    if (!updateTask) return

    updateTask.completed = !updateTask.completed
    const { completed, id } = updateTask

    try {
      apiPatch(`tasks/${id}/`, { completed })
    } catch (e) {
      alert('The update to the task item has failed. Try again later.')
      console.log('e:', e)
    } finally {
      setTasks(updatedTasks)
    }
  }

  const handleDelete = (id: Task['id']) => {
    apiDelete(`tasks/${id}/`)
    setTasks(tasks.filter((task) => task.id !== id.toString()))
  }

  return (
    <>
      {tasks.map((task) => {
        const { id, title, completed } = task
        return (
          <div key={id} className="flex">
            <div>
              <Checkbox
                value={id}
                className="ml-40"
                checked={completed ?? false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggle(e.target.value)
                }}
              />
            </div>
            <div> {title} </div>
            <button onClick={() => setUpdatingTaskId(id)}> Update</button>
            <button
              className="mt-1 rounded bg-white px-4 py-2 font-bold hover:bg-red-700 hover:text-white"
              onClick={() => handleDelete(id)}
            >
              <BsTrash />
            </button>
          </div>
        )
      })}
    </>
  )
}
export default TaskListTable
