import { ReactComponent as UserIcon } from '@zendeskgarden/svg-icons/src/16/user-circle-stroke.svg'
import React from 'react'

interface SmallProfileIconProps {
  firstName: string
}

const SmallProfileIcon: React.FC<SmallProfileIconProps> = ({ firstName }) => {
  return (
    <div className="group mr-4 flex flex-col items-center">
      <div className="relative">
        <UserIcon />
        <p className="absolute bottom-0 hidden rounded-md bg-gray-800 px-2 py-1 text-xs text-white group-hover:block">
          {firstName}
        </p>
      </div>
    </div>
  )
}

export default SmallProfileIcon
