import React, { useState, useRef, useEffect } from 'react'
import { HiDotsVertical } from 'react-icons/hi'

import { MenuOption } from '@/types/frontend'

interface KebabMenuProps {
  options: MenuOption[]
}

const KebabMenu: React.FC<KebabMenuProps> = ({ options }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        closeMenu()
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className="relative" ref={buttonRef}>
      {!!options.length && (
        <button
          onClick={toggleMenu}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <HiDotsVertical className="size-6" />
        </button>
      )}

      {isMenuOpen && (
        <div className="absolute right-0 top-full mt-2 w-48 rounded-md border border-gray-300 bg-white shadow-md">
          {options.map((option, index) => (
            <button
              key={index}
              className="cursor-pointer px-4 py-2 hover:bg-gray-100"
              onClick={() => {
                option.onClick()
                closeMenu()
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default KebabMenu
