import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg'
import React, { useState } from 'react'
import { ulid } from 'ulid'

import { PageLayout } from '@/components/page-layouts/page-layout'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import HorizontalTimeline from '@/components/ui/timelines/horizontal-timeline'
import MilestonesTable from '@/features/milestones/milestones-table'
import NewMilestone from '@/features/milestones/new-milestone'
import { useApiContext } from '@/lib/api-context'
import { useAppData } from '@/lib/data-provider'

export const NewProgramPlan = () => {
  return (
    <PageLayout>
      <NewProgramPlanPageContent />
    </PageLayout>
  )
}

const NewProgramPlanPageContent = () => {
  const { researcher } = useAppData()
  const [showMilestoneForm, setShowMilestoneForm] = useState(false)
  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | null
  >(null) // State for error message
  const [savingProgramPlan, setSavingProgramPlan] = useState<boolean>(false) // State for error message
  const { apiPost } = useApiContext()

  const [milestones, setMilestones] = useState<any[]>([])
  const [name, setName] = useState('')
  const [institution, setInstitution] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const addMilestone = (milestone: any) => {
    setMilestones([...milestones, milestone])
    setShowMilestoneForm(false)
  }

  const removeMilestone = (milestoneIndex: number) => {
    // Remove milestone by index
    const newMilestones = milestones
      .slice(0, milestoneIndex)
      .concat(milestones.slice(milestoneIndex + 1))
    setMilestones(newMilestones)
  }

  const validateAndSaveProgramPlan = async () => {
    if (!(name && milestones && institution && startDate && endDate)) {
      return setValidationErrorMessage('All required fields must be filled!')
    }
    setValidationErrorMessage(null)
    setSavingProgramPlan(true)
    try {
      await apiPost('program-plans/', {
        id: ulid(),
        name,
        institution,
        startDate,
        endDate,
        milestones,
        adminId: researcher.id,
      })
    } catch (e) {
      alert('There was an error in adding the program plan, try again later.')
      console.log('e:', e)
    }
    setSavingProgramPlan(false)
    window.history.back()
  }

  if (savingProgramPlan) {
    return <PageAnchorLoader />
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="flex items-center justify-center bg-gray-200">
        <div className="w-full rounded-md bg-white p-8 shadow-md md:w-2/3">
          <div className="mb-4 text-xl font-semibold">Add Program Plan</div>
          <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow md:col-span-2">
            <div className="mb-4">
              <label htmlFor="name" className="font-bold">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="font-bold">
                Institution
              </label>
              <input
                id="institution"
                name="institution"
                type="text"
                required
                className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
                value={institution}
                onChange={(e) => setInstitution(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="startDate" className="font-bold">
                Start Date
              </label>
              <input
                id="startDate"
                name="startDate"
                type="date"
                required
                className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="end_date" className="font-bold">
                End Date
              </label>
              <input
                id="end_date"
                name="end_date"
                type="date"
                required
                className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className=" mb-32">
              <div className="flex flex-col">
                <div className="flex items-center justify-between p-8">
                  <h1 className="grow text-2xl font-semibold">Milestones</h1>
                  {!showMilestoneForm && (
                    <button
                      className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
                      onClick={(e) => {
                        setShowMilestoneForm(true)
                        e.preventDefault()
                      }}
                    >
                      {' '}
                      <PlusIcon />{' '}
                    </button>
                  )}
                </div>
                {!showMilestoneForm && (
                  <div>
                    <MilestonesTable
                      milestones={milestones}
                      removeMilestone={removeMilestone}
                    />
                    <HorizontalTimeline events={milestones} />
                  </div>
                )}
                {showMilestoneForm && (
                  <NewMilestone
                    addMilestone={addMilestone}
                    setShowMilestoneForm={setShowMilestoneForm}
                  />
                )}
              </div>
            </div>
          </div>
          <button
            className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            onClick={validateAndSaveProgramPlan}
            type="submit"
          >
            Save
          </button>
          {validationErrorMessage && (
            <div className="m-4 rounded border border-red-400 bg-red-100 px-4 py-2 text-red-700">
              {validationErrorMessage}
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
