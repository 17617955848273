import React, { useState } from 'react'

import { Message, Project } from '@/types/types'

import ChatComponent from './chat-component'
import { QuickMessage } from './types/chat-types'

interface ChatMenuProps {
  projectId: Project['id']
  messageHistory: Message[]
}

const ChatMenu: React.FC<ChatMenuProps> = ({ projectId, messageHistory }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [quickMessages, setQuickMessages] = useState<QuickMessage[]>(
    messageHistory.map((message) => {
      const { id, sender } = message
      return { id, message: message.message, sender }
    }),
  )

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="fixed bottom-0 right-0 p-10">
      <div className="flex justify-end">
        <button
          className="m-2 rounded-full bg-blue-500 p-2 text-white hover:bg-blue-600"
          onClick={toggleMenu}
        >
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6 rotate-180"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
          )}
        </button>
      </div>
      {isOpen && (
        <ChatComponent
          quickMessages={quickMessages}
          setQuickMessages={setQuickMessages}
          projectId={projectId}
        />
      )}
    </div>
  )
}

export default ChatMenu
