import { useAuth0 } from '@auth0/auth0-react'
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { PageAnchorLoader } from '@/components/ui/loading/loading'
import { getProtectedResource, postProtectedResource } from '@/lib/api-service'
import { PartialResearcher, Researcher } from '@/types/types'

export interface AppData {
  researcherId: string
  researcher: Researcher
  allResearchers: PartialResearcher[]
}

interface DataProviderProps {
  children: ReactNode
}

const AppDataContext = createContext<AppData | null>(null)

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()

  const [researcherId, setResearcherId] = useState<string | null>(null)
  const [researcher, setResearcher] = useState<Researcher | null>(null)
  const [allResearchers, setAllResearchers] = useState<
    PartialResearcher[] | null
  >(null)

  useEffect(() => {
    let isMounted = true

    const fetchResourcesSequentially = async () => {
      try {
        const accessToken = await getAccessTokenSilently()

        const newUser = {
          email: user?.email,
          name: user?.name,
        }

        // Fetch the researcher Id
        const { data, error } = await postProtectedResource(
          accessToken,
          'get-or-create-user',
          newUser,
        )
        if (!isMounted) return

        if (data.researcher_id) {
          setResearcherId(data.researcher_id)
          const [response1, response2] = await Promise.all([
            getProtectedResource(
              accessToken,
              `researchers/${data.researcher_id}`,
            ),
            getProtectedResource(
              accessToken,
              'researchers/?fields=id,email,name',
            ),
          ])

          // Use Promise.all to fetch all resources concurrently
          if (!isMounted) return

          const researcher = response1.data
          const allResearchers = response2.data

          const error1 = response1.error
          const error2 = response2.error

          if (researcher) {
            setResearcher(researcher)
          }

          if (error1) {
            throw new Error(error1.message)
          }

          if (allResearchers) {
            setAllResearchers(allResearchers)
          }

          if (error2) {
            throw new Error(error2.message)
          }
        }

        if (error) {
          throw new Error(error.message)
        }
      } catch (error: any) {
        throw new Error(error)
      }
    }

    if (isAuthenticated) {
      fetchResourcesSequentially()
    }

    return () => {
      isMounted = false
    }
  }, [getAccessTokenSilently, isAuthenticated, user])

  if (allResearchers === null || researcherId == null || researcher == null) {
    return <PageAnchorLoader />
  }

  return (
    <AppDataContext.Provider
      value={{ allResearchers, researcherId, researcher }}
    >
      {children}
    </AppDataContext.Provider>
  )
}

export const useAppData = () => {
  const context = useContext(AppDataContext)
  if (!context) {
    throw new Error('useAppData must be used within a DataProvider')
  }
  return context
}
