import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab'
import React from 'react'
import { IoClose } from 'react-icons/io5'
import Modal from 'react-modal'

interface TimelineModalProps {
  isOpen: boolean
  onClose: () => void
  timelineEvents: { name: string; dueDate: string; description: string }[]
  selectedEvent: number | null
}

const TimelineModal: React.FC<TimelineModalProps> = ({
  isOpen,
  onClose,
  timelineEvents,
  selectedEvent,
}) => {
  const selectedEventData =
    selectedEvent !== null ? timelineEvents[selectedEvent] : null

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Timeline Modal"
      overlayClassName="overlay"
    >
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="relative w-3/4 rounded-md bg-white shadow-md">
          <div className="p-4">
            {/* Close Button */}
            <button className="absolute right-2 top-2 p-2" onClick={onClose}>
              <IoClose size={24} color="#333" />
            </button>

            {/* Title */}
            <h1 className="mb-4 text-2xl font-semibold">Timeline</h1>

            {/* Timeline */}
            <div className="flex w-full p-4">
              {/* Vertical Timeline */}
              <div className="mr-4 flex w-1/2 flex-col items-start">
                <Timeline>
                  {timelineEvents.map((event, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot
                          color={
                            selectedEvent === index ? 'secondary' : 'primary'
                          }
                        />
                        {index < timelineEvents.length - 1 && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent className="w-64">
                        <p>{event.name}</p>
                        <p>{event.dueDate}</p>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>

              {/* Selected Event Details */}
              {selectedEventData && (
                <div className="ml-4  w-1/2">
                  <h2 className="mb-2 text-lg font-semibold">
                    {selectedEventData.name}
                  </h2>
                  <p>{selectedEventData.description}</p>
                  {/* Add more details as needed */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default TimelineModal
