import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import * as React from 'react'

// function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
//   event.preventDefault()
//   console.info('You clicked a breadcrumb.')
// }

export default function Breadcrumb() {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/">
        Home
      </Link>
      <Link underline="hover" color="inherit" href="">
        Projects
      </Link>
      <Link underline="hover" color="text.primary" href="" aria-current="page">
        Test
      </Link>
    </Breadcrumbs>
  )
}
