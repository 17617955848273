import React from 'react'

import AddCollaboratorsList from '../ui/collaborator-lists/add-collaborators-list'
import CollaboratorsList from '../ui/collaborator-lists/collaborators-list'

const RightColumn: React.FC = () => {
  return (
    <div className="col-span-4 p-4 md:col-span-2">
      <CollaboratorsList />
      <AddCollaboratorsList />
    </div>
  )
}

export default RightColumn
