import React, { useEffect, useState } from 'react'
import '../../assets/styles/App.css'
import { useParams } from 'react-router-dom'

import { PageLayout } from '@/components/page-layouts/page-layout'
import ProgramCollaboratorsList from '@/components/ui/collaborator-lists/program-collaborators-list'
import { PageAnchorLoader } from '@/components/ui/loading/loading'
import HorizontalTimeline from '@/components/ui/timelines/horizontal-timeline'
import ProgramProjectList from '@/features/projects/program-project-list'
import UpdatesList from '@/features/updates/updates-list'
import { useApiContext } from '@/lib/api-context'
import { Program } from '@/types/types'

export const ProgramPage = () => {
  return (
    <PageLayout>
      <ProgramPageContent />
    </PageLayout>
  )
}

const ProgramPageContent = () => {
  const { apiGet } = useApiContext()
  const { programId } = useParams()
  const [program, setProgram] = useState<Program | null>()
  useEffect(() => {
    apiGet(`programs/${programId}/`).then((program) => setProgram(program))
  }, [apiGet, programId])

  if (!program) {
    return <PageAnchorLoader />
  }

  const { name, projects, supervisors, programMilestones } = program
  return (
    <div className="flex flex-1 items-center justify-center bg-gray-200 ">
      <div className="h-full w-2/3 flex-col rounded-md bg-white p-8 shadow-md">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-6 lg:grid-cols-6">
          <div className="col-span-6 mb-4 bg-white p-4 md:col-span-6">
            <label className="flex cursor-pointer items-center space-x-2 pb-4">
              <span className="pr-4 text-2xl font-semibold">{name}</span>
            </label>
            <ProgramCollaboratorsList
              supervisors={supervisors}
            ></ProgramCollaboratorsList>
          </div>
          <div className="col-span-6 mb-4 rounded border bg-white p-4 shadow">
            <HorizontalTimeline
              events={programMilestones.map(
                (programMilestone) => programMilestone.milestone,
              )}
            />
          </div>
          <div className="col-span-2 mb-4 rounded border bg-white p-4 shadow">
            <ProgramProjectList projects={projects} options={[]} />
          </div>
          <div className="col-span-4 mb-4 rounded border bg-white p-4 shadow">
            <UpdatesList />
          </div>
        </div>
      </div>
    </div>
  )
}
