import React, { useState } from 'react'
import Modal from 'react-modal'

interface AddProjectModalProps {
  isOpen: boolean
  onClose: () => void
  onAddProject: (newProject: any) => void
  projects?: any
}

const blankProject = {
  name: '',
}

const AddProjectModal: React.FC<AddProjectModalProps> = ({
  isOpen,
  onClose,
  onAddProject,
  projects,
}) => {
  const [newProject, setNewProject] = useState(blankProject)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setNewProject((prevProject) => ({ ...prevProject, [name]: value }))
  }

  const handleAddProject = () => {
    onAddProject(newProject)
    setNewProject(blankProject)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Task Modal"
      overlayClassName="overlay"
    >
      <div className="absolute left-1/2 top-1/2 w-1/4 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white p-4 shadow-md">
        <h2 className="mb-4 text-2xl font-semibold">Add Project</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleAddProject()
          }}
        >
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={newProject.name}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border p-2"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            >
              Add Project
            </button>
            <button
              type="button"
              onClick={onClose}
              className="rounded-md bg-gray-300 px-4 py-2 text-gray-700"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddProjectModal
