import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

export const Callback: React.FC = () => {
  const { error } = useAuth0()

  if (error) {
    return (
      <div>
        <h1 id="page-title" className="">
          Error
        </h1>
        <div className="">
          <p id="page-description">
            <span>{error.message}</span>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="">
      <div className="" />
    </div>
  )
}
