import Box from '@mui/material/Box'
import React, { ReactNode } from 'react'

import { ApiContextProvider } from '@/lib/api-context'
import { DataProvider } from '@/lib/data-provider'

import { NewSideBar } from '../menus/new-side-bar'

import { PageFooter } from './page-footer'

interface PageLayoutProps {
  children: ReactNode
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <DataProvider>
      <ApiContextProvider>
        <Box sx={{ backgroundColor: '#f3f4f6', fontFamily: 'Montserrat' }}>
          <NewSideBar>
            <Box sx={{ backgroundColor: '#f3f4f6', fontFamily: 'Montserrat' }}>
              <Box sx={{ padding: 0 }}>{children}</Box>
            </Box>
            <PageFooter />
          </NewSideBar>
        </Box>
      </ApiContextProvider>
    </DataProvider>
  )
}
