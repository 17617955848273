import React from 'react'
import { BsTrash } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { ProgramPlan } from '@/types/types'

const ProgramPlanTable: React.FC<{
  programPlans: ProgramPlan[]
  setProgramPlanDeletionId: (id: ProgramPlan['id']) => void
}> = ({ programPlans, setProgramPlanDeletionId }) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col">
      {programPlans.map((programPlan: any) => {
        const { id, name, institution, startDate, endDate } = programPlan
        return (
          <div key={id} className="flex">
            <button
              onClick={() => navigate(`/program-plans/${id}`)}
              className={'cursor-pointer'}
            >
              {name}
            </button>
            <div> {institution} </div>
            <div> {startDate} </div>
            <div> {endDate} </div>
            <button
              className="rounded bg-white p-1 font-bold hover:bg-red-700 hover:text-white"
              onClick={() => setProgramPlanDeletionId(id)}
            >
              <BsTrash />
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default ProgramPlanTable
