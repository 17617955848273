import { Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

interface ProjectTaskCardProps {
  name: string
  dueDate: string
  status: string
  assignee: string
  topBorderColor: string
  assignedProject: string
}

const ProjectTaskCard: React.FC<ProjectTaskCardProps> = ({
  name,
  dueDate,
  status,
  assignee,
  topBorderColor,
  assignedProject,
}) => {
  return (
    <Card sx={{ maxHeight: 200, position: 'relative' }}>
      <Box
        sx={{
          height: '4px',
          backgroundColor: topBorderColor,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Due Date:
            </Typography>
            <Typography variant="body2">{dueDate}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Status:
            </Typography>
            <Chip label={status} color="error" size="small" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Assignee:
            </Typography>
            <Chip label={assignee} size="small" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Assigned Project:
            </Typography>
            <Typography variant="body2">{assignedProject}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProjectTaskCard
