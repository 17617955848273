import '@/assets/styles/App.css'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { PageLayout } from '@/components/page-layouts/page-layout'
import Breadcrumb from '@/components/ui/breadcrumbs/breadcrumbs'
import ChipsArray from '@/components/ui/collaborator-lists/collaborators-horizontal-list'
import NoteCardContainer from '@/features/notes/note-card-container'
import ProjectTaskList from '@/features/tasks/project-task-list'

export const HomePage = () => {
  return (
    <PageLayout>
      <Grid container>
        <Breadcrumb />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary">
            Welcome to My Project
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ChipsArray />
        </Grid>
        <Grid item xs={4}>
          <ProjectTaskList />
        </Grid>
        <Grid item xs={8}>
          <NoteCardContainer />
        </Grid>
      </Grid>
    </PageLayout>
  )
}
