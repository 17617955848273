import { ReactComponent as CloseIcon } from '@zendeskgarden/svg-icons/src/16/x-fill.svg'
import React, { SetStateAction, Dispatch, FC, useState } from 'react'
import Modal from 'react-modal'

import { DotsLoader } from '@/components/ui/loading/loading'
import { File as ServerFile } from '@/types/types'

const AddFolderModal: FC<{
  addFolderModalOpen: boolean
  setAddFolderModalOpen: Dispatch<SetStateAction<boolean>>
  isUploadingFolder: boolean
  handleFolderUpload: (folderName: string) => Promise<void>
  siblingFolderNames: Set<ServerFile['name']>
}> = ({
  addFolderModalOpen,
  setAddFolderModalOpen,
  isUploadingFolder,
  handleFolderUpload,
  siblingFolderNames,
}) => {
  const [folderName, setFolderName] = useState('')
  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | null
  >(null) // State for error message

  const addFolder = async () => {
    if (!folderName) {
      return setValidationErrorMessage('Provide a folder name.')
    }
    if (siblingFolderNames.has(folderName)) {
      return setValidationErrorMessage('Folder name already exists!')
    }
    await handleFolderUpload(folderName)
  }

  return (
    <Modal
      isOpen={addFolderModalOpen}
      contentLabel="Add File Modal"
      overlayClassName="overlay"
    >
      <div className="absolute left-1/2 top-1/2 w-1/4 -translate-x-1/2 -translate-y-1/2 rounded-md border-4 border-gray-400 bg-gray-100 p-4 shadow-md">
        <div className="flex justify-between">
          <h2 className="mb-4 text-2xl font-semibold">Add Folder</h2>
          <button
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            onClick={() => setAddFolderModalOpen(false)}
          >
            {' '}
            <CloseIcon />{' '}
          </button>
        </div>
        {isUploadingFolder ? (
          <DotsLoader />
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              id="folderName"
              value={folderName}
              placeholder="Enter folder name"
              required
              onChange={(e) => setFolderName(e.target.value)}
            />
            <button
              className="w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
              onClick={addFolder}
            >
              {' '}
              Add{' '}
            </button>
            {validationErrorMessage && (
              <div className="m-4 rounded border border-red-400 bg-red-100 px-4 py-2 text-red-700">
                {validationErrorMessage}
              </div>
            )}
          </form>
        )}
      </div>
    </Modal>
  )
}

export default AddFolderModal
