import React from 'react'

import CollaboratorsListItem from './collaborators-list-item'

const AddCollaboratorsList: React.FC = () => {
  const researchers = [
    {
      id: 3,
      name: 'Michael Thompson',
      subtext: 'UQ',
    },
  ]

  return (
    <section className="mb-4 rounded border bg-white p-4 shadow">
      <h2 className="mb-2 text-xl font-semibold">Add Collaborators</h2>
      <div>
        {researchers.map((researcher) => (
          <CollaboratorsListItem
            key={researcher.id}
            name={researcher.name}
            subtext={researcher.subtext}
          />
        ))}
      </div>
    </section>
  )
}

export default AddCollaboratorsList
