import { ReactComponent as CloseIcon } from '@zendeskgarden/svg-icons/src/12/x-fill.svg'
import React, { useState } from 'react'
import { ulid } from 'ulid'

const NewMilestone: React.FC<{
  addMilestone: any
  setShowMilestoneForm: any
}> = ({ addMilestone, setShowMilestoneForm }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null) // State for error message

  const validateAndAddMilestone = () => {
    if (!(name && description && dueDate)) {
      return setErrorMessage('All fields are required!')
    }
    setErrorMessage(null)
    addMilestone({ id: ulid(), name, description, dueDate })
  }

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-4 rounded border p-4 shadow">
          <div className="mb-4 flex justify-between">
            <div className="text-xl font-semibold ">Add Milestone</div>
            <button
              className="rounded bg-red-500 px-3 text-sm text-white hover:bg-red-700"
              onClick={(e) => {
                e.preventDefault()
                setShowMilestoneForm(false)
              }}
            >
              {' '}
              <CloseIcon />{' '}
            </button>
          </div>
          <div className="my-4">
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              required
              className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="my-4">
            <label htmlFor="description" className="font-bold">
              Description
            </label>
            <input
              id="description"
              name="description"
              type="text"
              required
              className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="dueDate" className="font-bold">
              End Date
            </label>
            <input
              id="dueDate"
              name="dueDate"
              type="date"
              required
              className="mt-2 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </div>
          <div className="mt-2 flex w-full justify-center">
            <button
              className="mb-4 mr-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
              onClick={validateAndAddMilestone}
              type="submit"
            >
              Add
            </button>
          </div>
          {errorMessage && (
            <div className="mb-4 rounded border border-red-400 bg-red-100 px-4 py-2 text-red-700">
              {errorMessage}
            </div>
          )}
        </div>
      </form>
    </>
  )
}
export default NewMilestone
