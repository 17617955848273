import { ReactComponent as UserIcon } from '@zendeskgarden/svg-icons/src/16/user-circle-stroke.svg'
import React from 'react'

import { useAppData } from '@/lib/data-provider'

const ProfileSummary = () => {
  const { researcher } = useAppData()
  return (
    <section className="mb-4 rounded border bg-white p-4 shadow">
      <UserIcon className="mb-1.5 mr-2 inline" />
      <h2 className="mb-2 inline text-xl font-semibold">{researcher.email}</h2>
      <p className="text-gray-700">{researcher.email}</p>
    </section>
  )
}

export default ProfileSummary
