import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import HomeIcon from '@mui/icons-material/Home'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import PeopleIcon from '@mui/icons-material/People'
import SchoolIcon from '@mui/icons-material/School'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import * as React from 'react'

const categories = [
  {
    id: 'Pages',
    children: [
      {
        id: 'Home',
        icon: <HomeIcon />,
        active: true,
      },
      { id: 'Projects', icon: <LibraryBooksIcon /> },
      { id: 'Programs', icon: <PeopleIcon /> },
      { id: 'Calendar', icon: <CalendarTodayIcon /> },
    ],
  },
  {
    id: 'Projects',
    children: [
      { id: 'Quantum Computing', icon: <MenuBookIcon /> },
      { id: 'Research Radar', icon: <MenuBookIcon /> },
    ],
  },
  {
    id: 'Programs',
    children: [{ id: 'Max', icon: <SchoolIcon /> }],
  },
]

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
}

export default function BaseNavigator(props: DrawerProps) {
  const { ...other } = props

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{
            ...item,
            ...itemCategory,
            fontSize: 22,
            backgroundColor: '#2D3034',
            '&:hover': {
              backgroundColor: '#2D3034', // Keep the same background color on hover
            },
          }}
        >
          <img src="/images/brand.png" alt="research dock logo" />
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#2D3034' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={active} sx={item}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  )
}
